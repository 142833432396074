@if (!template) {
  <div class="d-flex gap-2 justify-content-center">
    @if (!isFree && price?.discount && price?.discount > 0) {
      <ultra-game-discount [discountBasis]="price.discount"></ultra-game-discount>
    }
    @if ({ discount: price?.discount > 0 }; as state) {
      @if (isFree || price) {
        <button
          class="price btn btn--usual btn-{{ btnSize }} btn--{{ theme }}"
          [class.price--discount]="state.discount"
          ultraAutotestAttribute="games-price">
          @if (isFree) {
            <ng-container i18n>Free</ng-container>
          } @else {
            @if (state.discount) {
              <ultra-game-price-discount [price]="price"></ultra-game-price-discount>
            } @else {
              <ng-container>{{ getPrice(price) }}</ng-container>
            }
          }
        </button>
      }
    }
  </div>
} @else {
  <ng-container *ngTemplateOutlet="template; context: settings"></ng-container>
}
