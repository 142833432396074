import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutotestAttributeDirective } from '../../autotests';
import { SharedPipesModule } from '../../pipes';

import { WalletBalanceComponent } from './components/wallet-balance/wallet-balance.component';

@NgModule({
  imports: [CommonModule, SharedPipesModule, AutotestAttributeDirective],
  declarations: [WalletBalanceComponent],
  exports: [WalletBalanceComponent],
})
export class WalletBalanceModule {}
