import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';

import { MainStorageService } from '@ultra/core/services';

import { WalletBalanceFacadeService } from '../../../../stores/wallet-balance/wallet-balance-facade.service';

const storageKey = 'isMarketPlaceBalanceVisible';

@Component({
  selector: 'ultra-wallet-balance',
  templateUrl: './wallet-balance.component.html',
})
export class WalletBalanceComponent implements OnInit {
  public walletBalance: number;
  public isBalanceHidden = false;
  public initStorageCheck = false;

  constructor(
    private walletBalanceFacadeService: WalletBalanceFacadeService,
    private storageService: MainStorageService
  ) {}

  public ngOnInit() {
    this.storageService.get(storageKey).subscribe((res) => {
      this.initStorageCheck = true;
      try {
        this.isBalanceHidden = JSON.parse(res);
      } catch (e) {
        this.isBalanceHidden = false;
      }
    });

    this.walletBalanceFacadeService.walletBalance$.pipe(take(1)).subscribe((balance) => {
      this.walletBalance = balance.uos;
    });
  }

  public toggleBalance() {
    this.isBalanceHidden = !this.isBalanceHidden;
    this.storageService.set(storageKey, this.isBalanceHidden.toString()).subscribe();
  }
}
