<div
  class="input-group input-group-sm search d-flex flex-nowrap {{ additionalClass }}"
  [class.search-disabled]="disabled"
>
  <div class="input-group-text bg-transparent border-0">
    <i class="icon icon-search {{ searchInput ? 'bg-white' : 'bg-white-alpha-3' }}"> </i>
  </div>

  <input
    class="search__input form-control py-2 border-end-4 border-0 bg-transparent text-truncate h-100"
    name="searchInput"
    autocomplete="off"
    spellcheck="false"
    [class.search__input--expandable]="expandable"
    [placeholder]="placeholder"
    [(ngModel)]="searchInput"
    (ngModelChange)="changed($event)"
    [disabled]="disabled"
    (focus)="onFocus()"
    [ultraAutotestAttribute]="dataId"
  />
</div>
