import { PaginationState } from './card-carousel';

export const fillResultsWithDuplicatedData = (data: any[], itemsPerPage: number): any[] => {
  const pagesWithoutDuplications = data.slice(0, itemsPerPage * Math.floor(data.length / itemsPerPage));
  // Items from the last page
  const lastPage = data.slice(-itemsPerPage);
  return [...pagesWithoutDuplications, ...lastPage];
};

export const fillResultsWithPlaceholders = (data: any[], pagination: PaginationState): any[] => {
  const placeholders = new Array(pagination.perPage * pagination.pages - data.length).fill(null);
  return [...data, ...placeholders];
};

/**
 * Paginates to the left by updating the pagination state.
 */
export const paginateLeft = (paginationState: PaginationState): PaginationState => ({
  ...paginationState,
  ...{
    startIndex: paginationState.startIndex - paginationState.perPage,
    endIndex: paginationState.endIndex - paginationState.perPage,
    page: --paginationState.page,
  },
});

/**
 * Paginates to the left by updating the pagination state.
 */
export const paginateRight = (paginationState: PaginationState): PaginationState => ({
  ...paginationState,
  ...{
    startIndex: paginationState.startIndex + paginationState.perPage,
    endIndex: paginationState.endIndex + paginationState.perPage,
    page: ++paginationState.page,
  },
});

/**
 * Paginate to the first page by updating the pagination state.
 * @param paginationState The current pagination state.
 * @returns The updated pagination state after paginating to the first page.
 */
export const paginateFirstPage = (paginationState: PaginationState): PaginationState => ({
  startIndex: 0,
  endIndex: paginationState.perPage,
  page: 1,
  perPage: paginationState.perPage,
  pages: paginationState.pages,
});
