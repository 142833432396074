<div
  class="expansion-panel__header d-flex align-items-center w-100 rounded {{ arrowPosition }} {{ additionalClass }}"
  [class.expansion-panel__header--hovered]="hover && !disabled"
  [class.expansion-panel__header--active]="showActiveState && active"
  [class.expansion-panel__header--disabled]="disabled"
>
  <div
    class="expansion-panel__header-content text-uppercase ls-one-and-half w-100"
    [class.text-uppercase]="!noUppercase"
  >
    <ng-content></ng-content>
  </div>

  <i
    class="expansion-panel__icon icon {{ active ? 'icon-triangle-lg-bottom' : 'icon-triangle-lg-right' }}"
    [class.align-self-start]="fixPosition"
    [class.expansion-panel__icon--disabled]="disabled"
    [hidden]="hideToggle"
  ></i>
</div>
<ng-content select=".footer"></ng-content>
