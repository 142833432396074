import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LimitTextComponent } from './components/limit-text.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LimitTextComponent],
  exports: [LimitTextComponent],
})
export class LimitTextModule {}
