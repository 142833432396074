import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutotestAttributeDirective } from '../../autotests';
import { WithdrawComponent } from '../withdraw/withdraw.component';

@NgModule({
  imports: [CommonModule, AutotestAttributeDirective],
  declarations: [WithdrawComponent],
  exports: [WithdrawComponent],
})
export class WithdrawModule {}
