import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';

@Component({
  selector: 'ultra-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnDestroy, AfterViewInit {
  private openPanelId: number = null;
  private subEventsList: Subscription[] = [];
  private accordionEvents: Subject<{ id: number; state?: boolean }> = new Subject();
  @ContentChildren(ExpansionPanelComponent, { descendants: true })
  private panels: QueryList<ExpansionPanelComponent>;

  @Input()
  multi = true; // all panels can be opened in the same time
  @Input()
  expanded = false; // all panels are closed by default
  @Output()
  initialized: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  updated: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private changeDetectionRef: ChangeDetectorRef) {}

  ngOnDestroy() {
    this.subEventsList.forEach((sub) => sub.unsubscribe());
  }

  ngAfterViewInit() {
    this.initPanels();
    this.listenAccordionEvents();
    this.listenQueryListChanges();
    this.checkExpandedState();
  }

  openAll(): void {
    this.changePanelsState(true);
  }

  closeAll(): void {
    this.changePanelsState(false);
  }

  private checkExpandedState(): void {
    if (this.expanded) {
      this.openAll();

      setTimeout(() => {
        this.changeDetectionRef.detectChanges();
      });
    }
  }

  private changePanelsState(state: boolean): void {
    this.openPanelId = null;

    this.panels.forEach((panel: ExpansionPanelComponent) => panel.updateState(state));
  }

  private initPanels(): void {
    this.panels.forEach((panel: ExpansionPanelComponent) => (panel.changeStateEvent = this.accordionEvents));

    setTimeout(() => {
      this.initialized.emit();
    });
  }

  /**
   * listen click events from panels
   */
  private listenAccordionEvents(): void {
    this.subEventsList.push(
      this.accordionEvents
        .pipe(tap((panel: { id: number; state?: boolean }) => (this.openPanelId = panel.id)))
        .subscribe((res: { id: number; state?: boolean }) => {
          if (this.multi) {
            const foundPanel: ExpansionPanelComponent = this.panels.find(
              (panel: ExpansionPanelComponent) => panel.id === res.id
            );

            foundPanel.updateState(res.state || !foundPanel.state);
          } else {
            this.panels.forEach((panel: ExpansionPanelComponent) => {
              panel.id === res.id ? panel.updateState(res.state || !panel.state) : panel.updateState(false);
            });
          }
          setTimeout(() => {
            this.updated.emit();
          });
        })
    );
  }

  /**
   * use for adding subject to new panels, if they were added dynamically, after initialization
   */
  private listenQueryListChanges(): void {
    this.subEventsList.push(
      this.panels.changes.subscribe((changes: QueryList<ExpansionPanelComponent>) => {
        changes.toArray().map((el: ExpansionPanelComponent) => (el.changeStateEvent = this.accordionEvents));
      })
    );
  }
}
