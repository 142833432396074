import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AutotestAttributeDirective } from '../../autotests';
import { CreateBtnComponent } from '../create-button/components/create-btn/create-btn.component';
import { CreateButtonModule } from '../create-button/create-button.module';
import { FieldTitleModule } from '../field-title/field-title.module';
import { LazyImageModule } from '../lazy-image/lazy-image.module';
import { PlaceholderLoadingModule } from '../placeholder-loading/placeholder-loading.module';
import { TagsInputModule } from '../tags-input';

import { BgCoverComponent } from './components/bg-cover/bg-cover.component';
import { BranchButtonComponent } from './components/branch-button/branch-button.component';
import { CircleLoaderComponent } from './components/circle-loader/circle-loader.component';
import { ProgressCircleComponent } from './components/progress-circle/progress-circle.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    LazyImageModule,
    PlaceholderLoadingModule,
    CreateButtonModule,
    AutotestAttributeDirective,
    PortalModule,
    FieldTitleModule,
    TagsInputModule,
  ],
  declarations: [ProgressCircleComponent, CircleLoaderComponent, BgCoverComponent, BranchButtonComponent],
  exports: [
    ProgressCircleComponent,
    CircleLoaderComponent,
    BgCoverComponent,
    BranchButtonComponent,
    CreateBtnComponent,
    AutotestAttributeDirective,
    FieldTitleModule,
    TagsInputModule,
  ],
})
export class DevCenterModule {}
