import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AutotestAttributeDirective } from '../../autotests';

import { SearchFormComponent } from './components/search-form/search-form.component';

@NgModule({
  imports: [AutotestAttributeDirective, CommonModule, FormsModule],
  declarations: [SearchFormComponent],
  exports: [SearchFormComponent],
})
export class SearchFormModule {}
