import { TokenFactoryContentType } from '../../models/game/enums/token-status.enum';
import { ITokenFactoriesByType, ITokenFactory } from '../../models/token-factory/interfaces/token-factory.interface';

export class TokenFactoryByTypeHelper {
  public static initTokenFactoriesByType(tokenFactories: ITokenFactory[]) {
    return tokenFactories && 0 < tokenFactories.length
      ? tokenFactories.reduce((acc, tokenFactory) => {
          if (tokenFactory && tokenFactory.contentType) {
            acc[tokenFactory.contentType.code] = acc[tokenFactory.contentType.code] || [];
            acc[tokenFactory.contentType.code].push(tokenFactory);
            acc[tokenFactory.contentType.code] = TokenFactoryByTypeHelper.sortTokenFactoryType(
              acc,
              tokenFactory.contentType.code
            );
          }
          return acc;
        }, {})
      : {};
  }

  public static findTokenFactoryGameTypeById(tokenFactoryByType: ITokenFactoriesByType, id: string): ITokenFactory {
    return tokenFactoryByType[TokenFactoryContentType.GAME].find(
      (tokenFactoryGameType) => tokenFactoryGameType.id === id
    );
  }

  private static sortTokenFactoryType(tokenFactoryByType: ITokenFactoriesByType, contentType: string): ITokenFactory[] {
    return tokenFactoryByType[contentType].sort((startTokenFactory, nextTokenFactory) => {
      const startTokenAmount = startTokenFactory.freeToPlay ? 0 : startTokenFactory.livePrices[0].amount;
      const nextTokenAmount = nextTokenFactory.freeToPlay ? 0 : nextTokenFactory.livePrices[0].amount;

      const sortAsc =
        startTokenAmount === nextTokenAmount
          ? startTokenFactory.publishOrder > nextTokenFactory.publishOrder
          : startTokenAmount > nextTokenAmount;
      return sortAsc ? 1 : -1;
    });
  }
}
