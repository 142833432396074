import { gql } from 'apollo-angular';

export const REDEEM_CODE = gql`
  mutation redeem($code: String!) {
    redeem(code: $code) {
      onChainId
      transactionId
    }
  }
`;
