import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { AutotestAttributeDirective } from '../../autotests';
import { DirectivesModule } from '../../directives/directives.module';
import { ScrollDirective } from '../../directives/scroll/scroll.directive';
import { BreadcrumbsModule, CopyToClipboardComponent } from '../../modules';
import { LanguageFormatterPipe } from '../../pipes/languages/languages.pipe';
import { SharedLanguagePipeModule } from '../../pipes/languages/languages.pipe.module';
import { SharedPipesModule } from '../../pipes/pipes.module';
import { AccordionModule } from '../accordion/accordion.module';
import { DevCenterModule } from '../dev-center/dev-center.module';
import { FormModule } from '../form/form.module';
import { GameModule } from '../game/game.module';
import { GamesSlideshowModule } from '../games-slideshow/games-slideshow.module';
import { ActionsStatusComponent } from '../id-card-content/id-card-actions/components/actions-status/actions-status.component';
import { LazyImageModule } from '../lazy-image/lazy-image.module';
import { MetascoreModule } from '../metascore/metascore.module';
import { PlaceholderLoadingModule } from '../placeholder-loading/placeholder-loading.module';
import { ContentSkeletonComponent } from '../skeleton/content-skeleton/content-skeleton.component';
import { ContentSkeletonDirective } from '../skeleton/content-skeleton/directive/content-skeleton.directive';
import { TabsModule } from '../tabs/tabs.module';
import { ToggleButtonComponent } from '../toggle-button/toggle-button.component';
import { TokenFactoryPreviewModule } from '../token-factory-preview/token-factory-preview.module';
import { VersionCurrencyModule } from '../version-currency/version-currency.module';

import { GameDetailAboutComponent } from './components/game-detail-about/game-detail-about.component';
import { GameDetailBackgroundContainerComponent } from './components/game-detail-background-container/game-detail-background-container.component';
import { GameDetailDetailComponent } from './components/game-detail-detail/game-detail-detail.component';
import { GameDetailRatingsModalComponent } from './components/game-detail-detail/game-detail-ratings-modal/game-detail-ratings-modal.component';
import { GameDetailTagsModalComponent } from './components/game-detail-detail/game-detail-tags-modal/game-detail-tags-modal.component';
import { GameDetailFeaturesComponent } from './components/game-detail-features/game-detail-features.component';
import { GameDetailLanguagesComponent } from './components/game-detail-languages/game-detail-languages.component';
import { GameDetailPreviewComponent } from './components/game-detail-preview/game-detail-preview.component';
import { GameDetailRequirementsComponent } from './components/game-detail-requirements/game-detail-requirements.component';
import { GameDetailBackgroundService } from './services/game-detail-background.service';

@NgModule({
  imports: [
    CommonModule,
    NgbDropdownModule,
    NgbModalModule,
    GameModule,
    GamesSlideshowModule,
    PlaceholderLoadingModule,
    FormModule,
    LazyImageModule,
    DirectivesModule,
    DevCenterModule,
    MetascoreModule,
    RouterModule,
    SharedLanguagePipeModule,
    SharedPipesModule,
    ToggleButtonComponent,
    AccordionModule,
    TabsModule,
    TokenFactoryPreviewModule,
    VersionCurrencyModule,
    NgbTooltipModule,
    BreadcrumbsModule,
    CopyToClipboardComponent,
    ContentSkeletonDirective,
    ContentSkeletonComponent,
    ActionsStatusComponent,
    AutotestAttributeDirective,
    ScrollDirective,
  ],
  declarations: [
    GameDetailPreviewComponent,
    GameDetailAboutComponent,
    GameDetailDetailComponent,
    GameDetailTagsModalComponent,
    GameDetailRatingsModalComponent,
    GameDetailRequirementsComponent,
    GameDetailFeaturesComponent,
    GameDetailLanguagesComponent,
    GameDetailBackgroundContainerComponent,
  ],
  exports: [
    GameDetailPreviewComponent,
    GameDetailAboutComponent,
    GameDetailDetailComponent,
    GameDetailTagsModalComponent,
    GameDetailRatingsModalComponent,
    GameDetailRequirementsComponent,
    GameDetailFeaturesComponent,
    GameDetailLanguagesComponent,
  ],
  providers: [LanguageFormatterPipe, GameDetailBackgroundService],
})
export class GameDetailModule {}
