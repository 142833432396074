export class StorageMockImplementation implements Storage {
  [name: string]: any;

  readonly length: number;

  clear(): void {
    /*noop*/
  }

  getItem(key: string): string | null {
    return undefined;
  }

  key(index: number): string | null {
    return undefined;
  }

  removeItem(key: string): void {
    /*noop*/
  }

  setItem(key: string, value: string): void {
    /*noop*/
  }
}
