<div class="expansion-panel" ultraAutotestAttribute="expansion-panel-container">
  @if (label) {
    <ultra-expansion-panel-header
      #headerElement
      [hover]="hover"
      [hideToggle]="hideToggle"
      [additionalClass]="additionalHeaderClass"
      [arrowPosition]="arrowPosition"
      (click)="changePanelState()">
      {{ label }}
    </ultra-expansion-panel-header>
    <ultra-expansion-panel-body #bodyElement>
      <ng-content></ng-content>
    </ultra-expansion-panel-body>
  } @else {
    <div class="expansion-panel__header-wrapper" (click)="changePanelState()">
      <ng-content select="ultra-expansion-panel-header"></ng-content>
    </div>
    <ng-content select="[inner-content]"></ng-content>
    <ng-content select="ultra-expansion-panel-body"></ng-content>
    <ng-content select="ultra-expansion-panel-footer"></ng-content>
  }
</div>
