export enum DimensionTitle {
  USER = 'User',
  COMPANY = 'Company',
  ULTRA = 'Ultra',
}
export enum AppTitle {
  ULTRA_GAMES = 'Ultra Games',
  ULTRA_WALLET = 'Ultra Wallet',
  ULTRA_UNIQ_MARKETPLACE = 'Uniq Marketplace',
  ULTRA_ARENA = 'Ultra Arena',
  GAME_DEV_CENTER = 'Game Dev Center',
  ULTRA_CLOUD = 'UltraCloud',
  HELP_CENTER = 'Help Center',
  MASTER_CENTER = 'Master Center',
}

export enum AppId {
  ULTRA_GAMES = 'games',
  ULTRA_WALLET = 'wallet',
  ULTRA_UNIQ_MARKETPLACE = 'marketplace',
  ULTRA_ARENA = 'ultraarena',
  GAME_DEV_CENTER = 'game-dev-center',
  MASTER_CENTER = 'master-center',
  ULTRA_CLOUD = 'ultracloud',
  ULTRA_HELP = 'help',
}

export interface IDimension {
  title: DimensionTitle;
  icon: string;
  apps: IDimensionApp[];
  active?: boolean;
}

export interface IDimensionApp {
  id?: AppId;
  title: AppTitle;
  iconApp: string;
  iconDimension: string;
  url: string;
  showBrowserBar: boolean;
  showNewTabButton: boolean;
  disabled?: boolean;
  active?: boolean;
}
