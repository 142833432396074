import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ultra-expansion-panel-header',
  templateUrl: './expansion-panel-header.component.html',
  styleUrls: ['./expansion-panel-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionPanelHeaderComponent {
  active: boolean;

  @Input()
  arrowPosition: 'left' | 'right' = 'left';
  @Input()
  fixPosition: boolean;
  @Input()
  hover = true;
  @Input()
  additionalClass = '';
  @Input()
  hideToggle = false;
  @Input()
  showActiveState = false;
  @Input()
  noUppercase = true;
  @Input()
  disabled = false;
}
