import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutotestAttributeDirective } from '../../autotests';
import { SharedPipesModule } from '../../pipes';
import { CardListModule } from '../card-list/card-list.module';
import { LazyImageModule } from '../lazy-image/lazy-image.module';

import { DirectivesModule } from './../../directives';
import { GameCardListComponent } from './components/game-card-list/game-card-list.component';
import { GameDiscountComponent } from './components/game-discount/game-discount.component';
import { GamePriceComponent } from './components/game-price/game-price.component';
import { GamePriceDiscountComponent } from './components/game-price-discount/game-price-discount.component';

@NgModule({
  imports: [
    CommonModule,
    CardListModule,
    LazyImageModule,
    SharedPipesModule,
    AutotestAttributeDirective,
    DirectivesModule,
  ],
  declarations: [GamePriceComponent, GameCardListComponent, GameDiscountComponent, GamePriceDiscountComponent],
  exports: [GamePriceComponent, GameCardListComponent, GamePriceDiscountComponent, GameDiscountComponent],
})
export class GameModule {}
