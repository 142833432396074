import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutotestAttributeDirective } from '../../autotests';

import { FieldTitleComponent } from './field-title/field-title.component';

@NgModule({
  imports: [CommonModule, AutotestAttributeDirective],
  declarations: [FieldTitleComponent],
  exports: [FieldTitleComponent],
})
export class FieldTitleModule {}
