import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ultra-default-modal',
  templateUrl: './default-modal.component.html',
  styleUrls: ['./default-modal.component.scss'],
})
export class DefaultModalComponent implements OnInit {
  @Input() modalTitle: string;
  @Input() title: string; // todo GP-18856
  nbsBtnDisplayed: number;
  @Input() result: any;
  @Input() closeAction: boolean;
  @Input() triggerDismissOnClose = false;
  // Add css classes
  @Input() additionalHeaderClass = '';
  @Input() additionalFooterClass = '';
  @Input() additionalBodyClass = '';
  @Input() isLimited = false;
  @Input() htmlContent = '';
  @Input() titleDataId: string;
  @Input() submitDataId: string;
  @Input() dismissDataId: string;
  @Input() closeDataId: string;
  /**
   * Show a loading spinner
   * to indicate that the action is being processed
   */
  @Input() loading = false;

  // Close btn
  _closeBtn = true;
  @Input() set closeBtn(closeBtn: boolean) {
    this._closeBtn = closeBtn;
    this.countNbsBtnDisplayed();
  }
  get closeBtn() {
    return this._closeBtn;
  }

  private _closeBtnCssClass = 'btn-primary';
  @Input() set closeBtnCssClass(closeBtnCssClass: string) {
    this._closeBtnCssClass = closeBtnCssClass;
  }
  get closeBtnCssClass() {
    return this._closeBtnCssClass;
  }

  private _closeBtnName = 'ok';
  @Input() set closeBtnName(closeBtnName: string) {
    this._closeBtnName = closeBtnName;
  }
  get closeBtnName() {
    return this._closeBtnName;
  }

  private _disableClosing: boolean;
  @Input() set disableClosing(disableClosing: boolean) {
    this._disableClosing = disableClosing;
  }
  get disableClosing() {
    return this._disableClosing;
  }

  // Dismiss btn
  private _dismissBtn = true;
  @Input() set dismissBtn(dismissBtn: boolean) {
    this._dismissBtn = dismissBtn;
    this.countNbsBtnDisplayed();
  }
  get dismissBtn() {
    return this._dismissBtn;
  }

  private _dismissBtnName = 'cancel';
  @Input() set dismissBtnName(dismissBtnName: string) {
    this._dismissBtnName = dismissBtnName;
  }
  get dismissBtnName() {
    return this._dismissBtnName;
  }

  // Left btn
  private _leftBtn: boolean;
  @Input() set leftBtn(leftBtn: boolean) {
    this._leftBtn = leftBtn;
    this.countNbsBtnDisplayed();
  }
  get leftBtn() {
    return this._leftBtn;
  }

  private _leftBtnName = 'previous';
  @Input() set leftBtnName(leftBtnName: string) {
    this._leftBtnName = leftBtnName;
  }
  get leftBtnName() {
    return this._leftBtnName;
  }

  private _leftBtnCssClass = 'btn-dark';
  @Input() set leftBtnCssClass(leftBtnCssClass: string) {
    this._leftBtnCssClass = leftBtnCssClass;
  }
  get leftBtnCssClass() {
    return this._leftBtnCssClass;
  }

  private _enabledLeftBtn = true;
  @Input() set enabledLeftBtn(enabledLeftBtn: boolean) {
    this._enabledLeftBtn = enabledLeftBtn;
  }
  get enabledLeftBtn() {
    return this._enabledLeftBtn;
  }

  // Right btn
  private _rightBtn: boolean;
  @Input() set rightBtn(rightBtn: boolean) {
    this._rightBtn = rightBtn;
    this.countNbsBtnDisplayed();
  }
  get rightBtn() {
    return this._rightBtn;
  }

  private _rightBtnName = 'next';
  @Input() set rightBtnName(rightBtnName: string) {
    this._rightBtnName = rightBtnName;
  }
  get rightBtnName() {
    return this._rightBtnName;
  }

  private _rightBtnCssClass = 'btn-primary';
  @Input() set rightBtnCssClass(rightBtnCssClass: string) {
    this._rightBtnCssClass = rightBtnCssClass;
  }
  get rightBtnCssClass() {
    return this._rightBtnCssClass;
  }

  private _enabledRightBtn = true;
  @Input() set enabledRightBtn(enabledRightBtn: boolean) {
    this._enabledRightBtn = enabledRightBtn;
  }
  get enabledRightBtn() {
    return this._enabledRightBtn;
  }

  @Output() triggerClose: EventEmitter<any> = new EventEmitter();
  @Output() triggerDismiss: EventEmitter<any> = new EventEmitter();
  @Output() triggerClickLeftBtn: EventEmitter<void> = new EventEmitter();
  @Output() triggerClickRightBtn: EventEmitter<void> = new EventEmitter();

  private countNbsBtnDisplayed() {
    this.nbsBtnDisplayed = 0;
    if (this._closeBtn) {
      this.nbsBtnDisplayed++;
    }

    if (this._dismissBtn) {
      this.nbsBtnDisplayed++;
    }

    if (this._leftBtn) {
      this.nbsBtnDisplayed++;
    }

    if (this._rightBtn) {
      this.nbsBtnDisplayed++;
    }
  }

  constructor(public activeModal: NgbActiveModal) {}

  dismiss(close?: 'close'): void {
    (this.closeAction && !close) || this.triggerDismissOnClose
      ? this.triggerDismiss.emit()
      : this.activeModal.dismiss();
  }

  close(result: any): void {
    result ? this.activeModal.close(result) : this.triggerClose.emit();
  }

  setOnClickLeftBtn() {
    this.triggerClickLeftBtn.emit();
  }

  setOnClickRightBtn() {
    this.triggerClickRightBtn.emit();
  }

  ngOnInit() {
    this.countNbsBtnDisplayed();
  }
}
