import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FeatureFlag } from '@ultra/core/lib/services/feature-flag';
import { APP_CONFIG, IEnvironment } from '@ultra/environment';

import { NavbarTab } from '../../interfaces/navbar-tab.interface';

@Component({
  selector: 'ultra-navbar-layout',
  templateUrl: './navbar-layout.component.html',
  styleUrls: ['./navbar-layout.component.scss'],
})
export class NavbarLayoutComponent implements OnInit {
  FeatureFlag = FeatureFlag;

  @Input() logoUrl: string;

  @Input() tabs: NavbarTab[];

  @Input() showWallet = true;

  @Input() showCart = false;

  @Input() showDownload = false;

  @Input() showSearchGamesForm = false;

  @Input() showSearchLink = false;

  @Input() searchLinkLabel?: string;

  @Input() searchLinkUrl?: string;

  @Input() searchLinkUrlParams: object = {};

  searchLinkParams: object = {};
  downloadUrl: string;

  constructor(private router: Router, @Inject(APP_CONFIG) private env: IEnvironment) {
    this.downloadUrl = `${this.env.websiteRedirectUrl}/download`;
  }

  ngOnInit() {
    this.searchLinkParams = {
      ...this.searchLinkUrlParams,
      redirectUrl: this.router.url,
    };
  }
}
