<ng-content></ng-content>

<!-- EXAMPLE OF USE
<ultra-accordion>
    <ultra-expansion-panel>
        <ultra-expansion-panel-header>
            header
        </ultra-expansion-panel-header>

        <ultra-expansion-panel-body>
            Lorem ipsum dolor sit amet
        </ultra-expansion-panel-body>
    </ultra-expansion-panel>

    <ultra-expansion-panel label="headerTitle">
        Minus placeat quos veniam?
    </ultra-expansion-panel>
</ultra-accordion>
-->
