import { AccountCreationErrorCode } from '../enums/account-creation-error.enum';

export class AccountCreationError implements Error {
  name = 'AuthenticatorError';
  message: string;
  code: AccountCreationErrorCode;

  constructor(message?: string, code?: AccountCreationErrorCode) {
    this.message = message;
    this.code = code;
  }
}
