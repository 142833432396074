<div class="modal-header">
  <h5 class="modal-title f-size-16 mb-0" [ultraAutotestAttribute]="titleDataId" [ngClass]="additionalHeaderClass">
    {{ modalTitle }}
  </h5>

  <button type="button" class="btn-close" [ultraAutotestAttribute]="dismissDataId" (click)="dismiss('close')">
    <i class="icon icon-bold-cross"></i>
  </button>
</div>

<div class="modal-body" [ngClass]="additionalBodyClass">
  @if (htmlContent) {
    <div [innerHTML]="htmlContent"></div>
  } @else {
    @if (false === isLimited) {
      <ng-container *ngTemplateOutlet="content"></ng-container>
    } @else {
      <div ultraScroll>
        <div class="limited-content">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
      </div>
    }
  }
</div>

<div class="modal-footer {{ additionalFooterClass }}">
  <ng-content select="div.footer"></ng-content>
  <div
    [class.justify-content-between]="nbsBtnDisplayed > 1"
    [class.justify-content-end]="nbsBtnDisplayed === 1"
    class="modal-footer-buttons">
    @if (leftBtn) {
      <button
        class="btn btn-lg fw-bold"
        [ngClass]="leftBtnCssClass"
        [disabled]="!enabledLeftBtn"
        (click)="setOnClickLeftBtn()">
        {{ leftBtnName }}
      </button>
    }
    @if (dismissBtn) {
      <button class="btn btn-dark btn-lg fw-bold" [ultraAutotestAttribute]="closeDataId" (click)="dismiss()">
        {{ dismissBtnName }}
      </button>
    }
    @if (closeBtn) {
      <button
        class="btn btn-lg fw-bold {{ closeBtnCssClass }}"
        [disabled]="disableClosing"
        (click)="close(result)"
        [ultraAutotestAttribute]="submitDataId">
        {{ closeBtnName }}
        @if (loading) {
          <div
            class="btn-spinner btn-spinner-light btn-spinner-slow"
            ultraAutotestAttribute="loader-spinner"
          ></div>
        }
      </button>
    }
    @if (rightBtn) {
      <button
        class="btn btn-lg fw-bold"
        [ngClass]="rightBtnCssClass"
        [disabled]="!enabledRightBtn"
        (click)="setOnClickRightBtn()"
        [ultraAutotestAttribute]="submitDataId">
        {{ rightBtnName }}
        @if (loading) {
          <div
            class="btn-spinner btn-spinner-light btn-spinner-slow"
            ultraAutotestAttribute="loader-spinner"
          ></div>
        }
      </button>
    }
  </div>
</div>

<!--It needs, because ng-content don't work inside *ngIf directive-->
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
