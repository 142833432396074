@if (visibility$ | async; as visibility) {
  @if (visibility.footer) {
    <div class="footer mt-1 py-4 text-end f-size-12 text-white-alpha-5">
      &copy; 2020&ndash;{{ year }}
      <ng-container i18n>
        Ultra Corporation. All rights reserved. All trademarks or product names are the property of their respective
        owners
      </ng-container>
      @if (visibility.legalButton) {
        <span class="mx-3">|</span>
        <a
          [href]="legalTermsLink"
          target="_blank"
          class="link text-ultra-purple-light"
          ultraAutotestAttribute="legal-link">
          <ng-container i18n>Legal</ng-container>
        </a>
      }
    </div>
  }
}
