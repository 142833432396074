import { IOperationSystem } from '../../models/game/interfaces/operation-system.interface';

export class UserHelper {
  /**
   * getting user platform from browser property navigator
   * @return {IOperationSystem}
   */
  static getUserOS(): IOperationSystem {
    const regExp = /\(([^)]+)\)/g;
    const userOS: string = navigator && navigator.userAgent.match(regExp)[0].toLowerCase();
    const result: IOperationSystem = {} as IOperationSystem;
    const osList: { [key: string]: string } = {
      win: 'windows',
      mac: 'osx',
      linux: 'ubuntu',
      vr: 'windows',
    };

    result.name = Object.keys(osList)
      .map((os: string) => (userOS.indexOf(os) > -1 || userOS.indexOf(osList[os]) > -1) && osList[os])
      .find((res) => !!res);
    result.bit = userOS.match(/(64|32)/g) ? userOS.match(/(64|32)/g)[0] : 'NA';

    return result;
  }
}
