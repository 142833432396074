import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { FeatureFlagService } from '../services/feature-flag.service';

@Directive({
  selector: '[ultraFeatureFlag]',
})
export class FeatureFlagDirective implements OnInit {
  @Input() ultraFeatureFlag: string | string[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit() {
    if (this.featureFlagService.isEnabled(this.ultraFeatureFlag)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
