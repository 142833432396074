import { Inject, Injectable } from '@angular/core';

import { WINDOW } from '../../providers';

import { BrowserStorageService } from './browser-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService extends BrowserStorageService {
  constructor(@Inject(WINDOW) window: Window) {
    super(window.sessionStorage);
  }
}
