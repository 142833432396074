import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { FeatureFlagModule } from '@ultra/core/lib/services/feature-flag';

import { AutotestAttributeDirective } from '../../autotests';
import { DirectivesModule } from '../../directives';
import { ScrollDirective } from '../../directives/scroll/scroll.directive';
import { CollapsibleNavModule } from '../collapsible-nav/collapsible-nav.module';
import { ImageResizeModule } from '../image-resize/image-resize.module';
import { LazyImageModule } from '../lazy-image/lazy-image.module';
import { WalletBalanceModule } from '../wallet-balance/wallet-balance.module';

import { NavbarCartComponent } from './components/navbar-cart/navbar-cart.component';
import { NavbarLayoutComponent } from './components/navbar-layout/navbar-layout.component';
import { NavbarNavComponent } from './components/navbar-nav/navbar-nav.component';
import { NavbarSearchComponent } from './components/navbar-search/navbar-search.component';
import { NavbarSearchFormComponent } from './components/navbar-search-form/navbar-search-form.component';
import { NavbarSearchLinkComponent } from './components/navbar-search-link/navbar-search-link.component';
import { NavbarSearchLinkIconComponent } from './components/navbar-search-link-icon/navbar-search-link-icon.component';
import { NavbarUserProfileComponent } from './components/navbar-user-profile/navbar-user-profile.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModalModule,
    FormsModule,
    NgbDropdownModule,
    LazyImageModule,
    DirectivesModule,
    CollapsibleNavModule,
    WalletBalanceModule,
    FeatureFlagModule,
    ImageResizeModule,
    AutotestAttributeDirective,
    ScrollDirective,
  ],
  declarations: [
    NavbarUserProfileComponent,
    NavbarNavComponent,
    NavbarLayoutComponent,
    NavbarCartComponent,
    NavbarSearchComponent,
    NavbarSearchFormComponent,
    NavbarSearchLinkComponent,
    NavbarSearchLinkIconComponent,
  ],
  exports: [
    NavbarUserProfileComponent,
    NavbarNavComponent,
    NavbarLayoutComponent,
    NavbarSearchComponent,
    NavbarSearchFormComponent,
    NavbarSearchLinkComponent,
    NavbarSearchLinkIconComponent,
  ],
})
export class NavbarModule {}
