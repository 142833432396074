import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutotestAttributeDirective } from '../../autotests';

import { AccordionComponent } from './accordion.component';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { ExpansionPanelBodyComponent } from './expansion-panel-body/expansion-panel-body.component';
import { ExpansionPanelFooterComponent } from './expansion-panel-footer/expansion-panel-footer.component';
import { ExpansionPanelHeaderComponent } from './expansion-panel-header/expansion-panel-header.component';

@NgModule({
  imports: [CommonModule, AutotestAttributeDirective],
  exports: [
    AccordionComponent,
    ExpansionPanelComponent,
    ExpansionPanelBodyComponent,
    ExpansionPanelHeaderComponent,
    ExpansionPanelFooterComponent,
  ],
  declarations: [
    AccordionComponent,
    ExpansionPanelComponent,
    ExpansionPanelBodyComponent,
    ExpansionPanelHeaderComponent,
    ExpansionPanelFooterComponent,
  ],
})
export class AccordionModule {}
