import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutotestAttributeDirective } from '../../autotests';

import { CreateBtnComponent } from './components/create-btn/create-btn.component';

@NgModule({
  imports: [CommonModule, AutotestAttributeDirective],
  declarations: [CreateBtnComponent],
  exports: [CreateBtnComponent],
})
export class CreateButtonModule {}
