import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CardListComponent } from './card-list/card-list.component';
import { CardListItemDirective } from './card-list-item.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [CardListComponent, CardListItemDirective],
  exports: [CardListComponent, CardListItemDirective],
})
export class CardListModule {}
