import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MetascoreComponent } from './components/metascore/metascore.component';

@NgModule({
  imports: [CommonModule],
  declarations: [MetascoreComponent],
  exports: [MetascoreComponent],
})
export class MetascoreModule {}
