<div ultraDialogHeader>
  @switch (getContentState(data?.title)) {
    @case ('default') {
      <div i18n>Are you sure?</div>
    }
    @case ('custom') {
      <div i18n>{{ data?.title }}</div>
    }
    @case ('template') {
      <div *ngTemplateOutlet="data?.title"></div>
    }
  }
</div>
<div ultraDialogContent>
  @switch (getContentState(data?.content)) {
    @case ('default') {
      <div i18n>Are you sure that you want to perform this action?</div>
    }
    @case ('custom') {
      <div i18n>{{ data?.content }}</div>
    }
    @case ('template') {
      <div *ngTemplateOutlet="data?.content"></div>
    }
  }
</div>
<div ultraDialogActions>
  <button class="btn btn-outline-dark" ultraDialogClose>
    @switch (getContentState(data?.actions?.dismiss)) {
      @case ('default') {
        <div i18n>Cancel</div>
      }
      @case ('custom') {
        <div i18n>{{ data?.actions?.dismiss }}</div>
      }
      @case ('template') {
        <div *ngTemplateOutlet="data?.actions?.dismiss"></div>
      }
    }
  </button>
  <button class="btn btn-primary" [ultraDialogClose]="true">
    @switch (getContentState(data?.actions?.close)) {
      @case ('default') {
        <div i18n>Ok</div>
      }
      @case ('custom') {
        <div i18n>{{ data?.actions?.close }}</div>
      }
      @case ('template') {
        <div *ngTemplateOutlet="data?.actions?.close"></div>
      }
    }
  </button>
</div>
