<a
  class="cart d-flex align-items-center cursor-pointer"
  [class.cart--with-price]="totalFiat$ | async"
  (click)="transactionService.openBasket()">
  <i class="cart__icon icon icon-cart-sm"></i>

  @if (itemsCount$ | async; as itemsCount) {
    <span class="cart__count u-badge u-badge-danger">
      {{ itemsCount }}
    </span>
  }

  @if (totalFiat$ | async; as price) {
    <div class="cart__price f-mono ls-quoter ms-2">
      <ng-container>
        {{ getPrice(price) }}
      </ng-container>
    </div>
  }
</a>
