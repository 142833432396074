<div class="row flex-wrap h-100">
  <div class="col-md-7 col-sm-6 col-12 box-preview pe-md-0">
    @if (game.unavailableGameMedia()) {
      <div class="preview-container">
        <div class="d-flex h-100 justify-content-center align-content-center preview-hollow-box">
          <div class="align-self-center">
            <p class="hollow-box-text m-0">
              <ng-container i18n>Game media coming soon</ng-container>
            </p>
          </div>
        </div>
      </div>
      <div class="preview-thumbs thumbs-hollow-box d-flex ms-0 me-0">
        @for (box of hollowBoxes; track box) {
          <div class="preview-thumb-container preview-thumb-container-image cursor-pointer">
            <div class="preview-thumb preview-thumb-box">
              <i class="icon icon-placeholder-media"></i>
            </div>
          </div>
        }
      </div>
    }

    @if (game.hasPreviewImgs() || game.multimedia?.videosPreviews.length > 0) {
      <div class="preview-container">
        <ultra-lazy-image
          class="preview preview-image position-relative"
          [ngClass]="{ 'd-none': !currentPreviewImage }"
          [defaultImage]="currentPreviewImage && game.getImgBySize(currentPreviewImage.images) | resizeImage: 720 : 400"
          imageSize="m">
        </ultra-lazy-image>
        <div class="preview preview-video" [ngClass]="{ 'd-none': !currentPreviewVideo }">
          <ultra-loading></ultra-loading>
          <div class="player" [id]="'video-player-' + game.id"></div>
        </div>
      </div>
      <div class="preview-thumbs ms-0 me-0">
        <ultra-slider theme="slideshow">
          @if (game.multimedia.videosPreviews?.length && autoplay) {
            @for (preview_video of game.multimedia.videosPreviews; track preview_video) {
              <ultraSliderContent>
                <ng-template ultraSwiperTemplate>
                  <ultra-game-item-slideshow-image
                    [videoPreview]="true"
                    [isActive]="preview_video === currentPreviewVideo"
                    [defaultImage]="preview_video?.image?.base?.url"
                    styleClass="d-flex align-items-center justify-content-center w-100 h-100"
                    (click)="setCurrentPreviewVideo(preview_video)">
                  </ultra-game-item-slideshow-image>
                </ng-template>
              </ultraSliderContent>
            }
          }
          @for (preview_img of game.multimedia.imageGalleryList; track preview_img; let i = $index) {
            <!--Display the large preview by click-->
            @if (config.showByClick) {
              <!--Display .preview-thumb-container-video-->
              @if (1 === i && game.multimedia.videosPreviews?.length && !autoplay) {
                @for (preview_video of game.multimedia.videosPreviews; track preview_video) {
                  <ultraSliderContent>
                    <ng-template ultraSwiperTemplate>
                      <ultra-game-item-slideshow-image
                        [videoPreview]="true"
                        [isActive]="preview_video === currentPreviewVideo"
                        [defaultImage]="preview_video?.image?.base?.url"
                        styleClass="d-flex align-items-center justify-content-center w-100 h-100"
                        (click)="setCurrentPreviewVideo(preview_video)">
                      </ultra-game-item-slideshow-image>
                    </ng-template>
                  </ultraSliderContent>
                }
              }
              <!--End preview-thumb-container-video-->
              <ultraSliderContent>
                <ng-template ultraSwiperTemplate>
                  <ultra-game-item-slideshow-image
                    [isActive]="preview_img === currentPreviewImage"
                    [defaultImage]="getImgBySize(preview_img.images)"
                    (click)="setCurrentPreviewImage(preview_img)">
                  </ultra-game-item-slideshow-image>
                </ng-template>
              </ultraSliderContent>
            }
            <!--Display the large preview by hover-->
            @if (!config.showByClick) {
              <ultraSliderContent>
                <ng-template ultraSwiperTemplate>
                  <ultra-game-item-slideshow-image
                    [isActive]="preview_img === currentPreviewImage"
                    [defaultImage]="getImgBySize(preview_img.images)"
                    (mouseenter)="setCurrentPreviewImage(preview_img)">
                  </ultra-game-item-slideshow-image>
                </ng-template>
              </ultraSliderContent>
            }
          }
        </ultra-slider>
      </div>
    }
  </div>

  <div class="col-12 col-sm-6 col-md-5 box-info ps-sm-0 game" [class.slideshow]="config.inSlideshow">
    <div class="box-info__content d-flex flex-column p-2 ps-sm-0 p-md-4">
      <div class="box-info__content-top">
        @if (config.showName) {
          <h5 class="game__title pe-4">{{ game.title }}</h5>
        }

        @if (config.showEditor) {
          <small class="game__editor text-primary">{{ game.editorName }}</small>
        }

        @if (config.showShortDescription) {
          <h4 class="pe-2">{{ game.descriptionShort }}</h4>
        }
        <div
          ultraScroll
          useTopGradient="true"
          useBottomGradient="true"
          theme="dark"
          class="mt-4 scroll-container game__description b2-regular">
          {{ game.description }}
        </div>
      </div>

      @if (config.showPrice || config.showOs || config.showLearnBtn) {
        <div class="box-info__content-bottom d-flex mt-auto">
          <div class="d-flex flex-wrap align-items-center w-100">
            @if (config.showLearnBtn) {
              <div class="btn-more-btn-container align-self-end my-2 my-xl-0 order-1">
                <a
                  [routerLink]="['/store/games', game.slug, game.id]"
                  class="btn btn-dark btn-lg fw-bold btn-slide-show-min-width"
                  (click)="onViewGamePageClick()">
                  @if (game?.getTokenPrices()) {
                    <ng-container i18n="@@learnMore">Learn more</ng-container>
                  } @else {
                    <ng-container i18n>Details</ng-container>
                  }
                </a>
              </div>
            }
            @if (config.showOs) {
              <div class="game__os d-flex align-items-center align-self-end ms-auto ms-xl-3 order-2">
                @for (os of game.systemsRequirements; track os; let first = $first) {
                  <i class="icon bg-white-alpha-7 me-3 icon-os-name-{{ os.osName }}" [class.ms-1]="first"></i>
                }
              </div>
            }
            <div class="game__price d-flex align-items-end justify-content-start ms-auto order-3">
              @if (config.showPrice && (game.freeToPlay || getTokenPrices(game))) {
                <ultra-game-price
                  [isFree]="game.freeToPlay"
                  [price]="getTokenPrices(game)"
                  [routerLink]="['/store/games', game.slug, game.id]"
                  (click)="onViewGamePageClick()"></ultra-game-price>
              }
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>
