<div
  class="preview-thumb-container cursor-pointer"
  [ngClass]="{ 'preview-thumb-container-image': !videoPreview, 'col preview-thumb-container-video': videoPreview }">
  <ultra-lazy-image
    class="preview-thumb"
    [videoPreview]="videoPreview"
    [ngClass]="{
      active: isActive,
      'preview-thumb-image position-relative': !videoPreview,
      'preview-thumb-video': videoPreview
    }"
    [styleClass]="styleClass"
    [defaultImage]="defaultImage | resizeImage: 260 : 150"
    [imageSize]="imageSize"
    [styleClass]="'d-flex align-items-center justify-content-center w-100 h-100'">
    @if (videoPreview) {
      <i class="icon icon-play"></i>
    }
  </ultra-lazy-image>
</div>
