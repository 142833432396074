import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';

import { ExpansionPanelBodyComponent } from '../expansion-panel-body/expansion-panel-body.component';
import { ExpansionPanelHeaderComponent } from '../expansion-panel-header/expansion-panel-header.component';

@Component({
  selector: 'ultra-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  exportAs: 'expansionPanel',
})
export class ExpansionPanelComponent implements AfterContentInit {
  @ViewChild('bodyElement')
  private bodyEl: ExpansionPanelBodyComponent;
  @ViewChild('headerElement')
  private headerEl: ExpansionPanelHeaderComponent;
  @ContentChild(ExpansionPanelBodyComponent)
  private bodyRef: ExpansionPanelBodyComponent;
  @ContentChild(ExpansionPanelHeaderComponent)
  private headerRef: ExpansionPanelHeaderComponent;

  id: number;
  state = false;
  changeStateEvent: Subject<{ id: number; state?: boolean }>;

  private _disabled = false;

  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
    this.headerRef.disabled = value;
  }
  get disabled(): boolean {
    return this._disabled;
  }
  @Input()
  hover: boolean;
  @Input()
  hideToggle: boolean;
  @Input()
  label: string;
  @Input()
  additionalHeaderClass: string;
  @Input()
  arrowPosition: 'left' | 'right';
  @Output()
  closed: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  opened: EventEmitter<number> = new EventEmitter<number>();

  constructor(private changeDetectionRef: ChangeDetectorRef) {
    this.id = Math.round(Math.random() * +new Date());
  }

  ngAfterContentInit() {
    if (this.checkIsBodyTemplateEmpty()) {
      this.headerRef ? (this.headerRef.hideToggle = true) : (this.hideToggle = false);
    }

    this.updateState();
  }

  checkIsBodyTemplateEmpty(): boolean {
    return !this.bodyEl && !this.bodyRef;
  }

  /**
   * call after click on header
   * trigger check all panels states in accordion
   */
  changePanelState(state?: boolean): void {
    this.changeStateEvent.next({
      id: this.id,
      state,
    });
  }

  /**
   * change state of header and body
   * calls from accordion component, which is listening changePanelState
   * elements can have ViewChild or ContentChild type, because component can be used in 2 ways
   */
  updateState(state = this.state): void {
    if (!this.disabled) {
      const refs = [this.bodyRef, this.headerRef, this.bodyEl, this.headerEl];

      this.state = state;

      refs.map((ref: ExpansionPanelHeaderComponent | ExpansionPanelBodyComponent) => {
        if (ref) {
          setTimeout(() => {
            ref.active = state;

            this.changeDetectionRef.detectChanges();
          });
        }
      });

      state ? this.opened.emit(this.id) : this.closed.emit(this.id);
    }
  }

  open(): void {
    if (!this.disabled) {
      this.opened.emit(this.id);
      this.changePanelState(true);
    }
  }

  close(): void {
    if (!this.disabled) {
      this.closed.emit(this.id);
      this.changePanelState(false);
    }
  }
}
