import { Game } from '../models/game/models/game.model';

export function getGameEventData(game: Game) {
  const price = game.getTokenPrices();
  return {
    gameId: game.id,
    gameTitle: game.title,
    publisher: game.publisher?.name,
    price: price?.amount,
    originalPrice: price?.originalAmount,
    currency: price?.currency,
  };
}
