import { ChangeDetectorRef, Component } from '@angular/core';

@Component({
  selector: 'ultra-expansion-panel-body',
  templateUrl: './expansion-panel-body.component.html',
  styleUrls: ['./expansion-panel-body.component.scss'],
})
export class ExpansionPanelBodyComponent {
  get active() {
    return this._active;
  }

  set active(value) {
    this._active = value;
    this.cd.markForCheck();
  }

  private _active;

  constructor(private cd: ChangeDetectorRef) {}
}
