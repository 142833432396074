import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiGraphQLService } from '@ultra/core/graphql';

import { ApiSharedGqlConfig } from '../../../graphql-client';
import { IGame } from '../interfaces/game.interface';
import { Game } from '../models/game.model';

@Injectable({
  providedIn: 'root',
})
export class GameGqlService {
  constructor(private apiService: ApiGraphQLService) {}

  getCartGamesById(ids: string[]): Observable<Game[]> {
    return this.apiService.getQuery(ApiSharedGqlConfig.CART_GAMES, { ids }, { cache: false }).pipe(
      map((result: { data: { gamesByIds: { game: IGame }[] } }) => result.data.gamesByIds),
      map((result: { game: IGame }[]) => {
        const games: Game[] = [];

        for (const index in result) {
          if (Object.prototype.hasOwnProperty.call(result, index)) {
            games.push(new Game(result[index].game));
          }
        }
        return games;
      }),
    );
  }
}
