import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { APP_CONFIG, IEnvironment } from '@ultra/environment';

@Injectable({ providedIn: 'root' })
export class BlockchainService {
  private blockchainUrl: string;

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private readonly environment: IEnvironment) {
    this.blockchainUrl = `${this.environment.blockchainUrl}/v1/chain`;
  }

  public getAccountBalance(account: string): Observable<string> {
    return this.http
      .post<string[]>(`${this.blockchainUrl}/get_currency_balance`, {
        code: 'eosio.token',
        symbol: 'UOS',
        account,
      })
      .pipe(
        map(([amount]) => {
          return parseFloat(amount || '0').toFixed(8);
        }),
        catchError(() => {
          return of('0.0000');
        })
      );
  }

  getAccount(blockChainId: string) {
    const body = {
      account_name: blockChainId,
    };
    const headers = new HttpHeaders({
      accept: 'application/json',
      'content-type': 'application/json',
    });
    return this.http.post(`${this.blockchainUrl}/get_account`, body, { headers });
  }
}
