import { Pagination } from '../../base-interfaces/pagination.interface';
import { ILanguage } from '../../game/interfaces/language.interface';
import { IImageData } from '../../multimedia/interfaces';

import { ITokenFContent } from './content.interface';
import { ITokenFOrder } from './order.interface';
import { IRevenueShare } from './revenue-share.interface';
import { ITokenFactoryContentType } from './token-factory-content-type.interface';
import { ITokenPrice } from './token-price.interface';

export enum TokenFactoryType {
  INTERNAL = 'internal',
  RESTRICTED = 'restricted',
  STORE = 'store',
}

export enum TokenFactoryStatus {
  DRAFT = 'draft',
  READY_TO_PUBLISH = 'readyToPublish',
  PLANNED = 'planned',
  PENDING = 'pending',
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
  EXPIRED = 'expired',
  RESTRICTED = 'restricted',
}

export interface ITokenFactoryToPatch {
  publisherId?: string;
  name?: string;
  description?: string;
  factoryType?: TokenFactoryType;
  image?: IImageData;
  content?: ITokenFContent;
  prices?: ITokenPrice[];
  revenueShare?: IRevenueShare[];
  geofencing?: string[];
  multimedia?: {
    backGroundImage?: IImageData;
    imageGalleryList?: IImageData[];
  };
  languages?: ILanguage;
  contentTypeId?: string;
}

export interface ITokenFactory {
  id: string;
  onChainId?: number;
  status?: TokenFactoryStatus;
  blockchainId?: string;
  publisherId?: string;
  name?: string;
  description?: string;
  factoryType?: TokenFactoryType;
  freeToPlay?: boolean;
  image?: IImageData;
  content?: ITokenFContent;
  prices?: ITokenPrice[];
  revenueShare?: IRevenueShare[];
  geofencing?: string[];
  multimedia?: {
    backGroundImage?: IImageData;
    imageGalleryList?: IImageData[];
  };
  languages?: ILanguage;
  contentTypeId?: string;
  contentType?: ITokenFactoryContentType;
  order?: ITokenFOrder;
  publishOrder?: number;
  discounts?: IGameFactoryDiscount[];
  livePrices?: ITokenPrice[];
  exclusiveAccess?: ITokenFactoryExclusiveAccessKeys;
}

export interface ITokenFactoryExclusiveAccessKeys {
  uniqFactoryKeyIds: string[];
  requiredAmount?: number;
}

export interface IInternalTokenFactory extends ITokenFactory {
  factoryType?: TokenFactoryType.INTERNAL;
}

export interface IInternalTokenFactory extends ITokenFactory {
  factoryType?: TokenFactoryType.INTERNAL;
}

export interface IRestrictedTokenFactory extends ITokenFactory {
  factoryType?: TokenFactoryType.RESTRICTED;
}

export interface IStoreTokenFactory extends ITokenFactory {
  factoryType?: TokenFactoryType.STORE;
}

export interface IRevenueMap {
  remainingRevenue: number;
  studio?: number;
  promoter?: number;
}

export interface ITokenFactoriesByGame {
  nodes: ITokenFactory[];
  pagination: Pagination;
  totalCount: number;
}

export interface ITokenFactoriesByType {
  [key: string]: ITokenFactory[];
}

export interface IGameFactoryDiscount {
  id: string;
  discountBasisPoint: number; // between 1000 and 9000
  endDate: string;
  startDate: string;
}
