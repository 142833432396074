import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, iif, Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

import { AuthService } from '@ultra/core/auth';
import { FeatureFlag, FeatureFlagService } from '@ultra/core/lib/services/feature-flag';
import { AppId } from '@ultra/core/models';

import {
  DatePipeFormat,
  GameDetail,
  ITokenFactoriesByType,
  ITokenFactory,
  ITokenPrice,
  IUniq,
  TokenFactoryContentType,
  TokenStatus,
} from '../../../../models';
import { IBreadCrumb, IdCardEntity } from '../../../../modules';
import { ContentSkeletonTheme } from '../../../../modules/skeleton/content-skeleton/content-skeleton-theme.enum';
import { DeepLinkService } from '../../../../services/deeplink';
import { GameWishListService } from '../../../../services/game-wishlist/game-wishlist.service';
import { UniqGqlService } from '../../../../services/uniq/uniq-gql.service';
import { TokenPrices } from '../../../../utils';
import { IdCardAction } from '../../../id-card-content/id-card-actions/id-card-actions.model';
import { TokenFactoryPreviewFacadeService } from '../../../token-factory-preview/token-factory-preview-facade.service';
import { ShareUrlService } from '../../services/share-url.service';
/**
 * A component that shows detail information of a game.
 *
 * It is not being destroyed for each new game, instead it is being reused.
 */
@UntilDestroy()
@Component({
  selector: 'ultra-game-detail-preview',
  templateUrl: './game-detail-preview.component.html',
  styleUrls: ['./game-detail-preview.component.scss'],
  providers: [DecimalPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameDetailPreviewComponent extends TokenPrices implements OnInit, OnChanges {
  @Input() game: GameDetail;
  @Input() tokenFactoriesByType: ITokenFactoriesByType;
  @Input() href: string;
  @Input() preview: boolean;
  @Input() loading = true;
  @Input() hasGeofencingRestriction = false;
  /**
   * When there is a route change we show placeholders.
   */
  @Input() isRouteChange = false;

  @Output() toggleWishList = new EventEmitter<boolean>();
  @Output() scrollToOptions = new EventEmitter<void>();
  @Output() openShoppingCart = new EventEmitter<[GameDetail, ITokenFactory]>();

  private authService = inject(AuthService);

  readonly actions = IdCardAction;

  breadCrumbs: IBreadCrumb[];
  triggerTooltip: boolean;
  tokenStatus = TokenStatus;
  DatePipeFormat = DatePipeFormat;
  tokenFactoryContentType = TokenFactoryContentType;
  gameUrl: string;
  placeholderTheme = ContentSkeletonTheme;
  tokenFactory: ITokenFactory;
  favoritePendingStore$ = inject(GameWishListService).favoritePendingStore$;
  resalableToken: IdCardEntity | undefined;

  private tokenFactorySubject$: ReplaySubject<ITokenFactory[]> = new ReplaySubject<ITokenFactory[]>();
  gameTokenFactories$: ReplaySubject<ITokenFactory[]> = new ReplaySubject(1);
  private uniqGqlService = inject(UniqGqlService);
  gameBuyIsNotAllowed$: Observable<boolean> = this.gameTokenFactories$.pipe(
    filter(Boolean),
    map((tokenFactories) =>
      tokenFactories.filter((tokenFactory) => tokenFactory.exclusiveAccess?.uniqFactoryKeyIds?.length > 0),
    ),
    switchMap((tokenFactoriesWithExclusiveAccess) =>
      iif(
        () => tokenFactoriesWithExclusiveAccess.length === this.tokenFactoriesByType.game.length,
        of(true).pipe(
          filter(() => this.authService.isAuthenticated()),
          map(() =>
            tokenFactoriesWithExclusiveAccess
              .map((tokenFactory: ITokenFactory) => tokenFactory.exclusiveAccess?.uniqFactoryKeyIds)
              .flat(1),
          ),
          switchMap((uniqFactoryKeyIds: string[]) =>
            this.uniqGqlService.getUniqInventoryListByFactoryIds$({ factoryIds: uniqFactoryKeyIds }).pipe(
              map((res: { data: IUniq[] }) => res.data.map((uniq: IUniq) => uniq.factory.onChainId)),
              map((ownedUniqsIds: string[]) =>
                tokenFactoriesWithExclusiveAccess.every((tokenFactory: ITokenFactory) => {
                  if (tokenFactory.exclusiveAccess?.requiredAmount) {
                    const ownedAmount: number = tokenFactory.exclusiveAccess.uniqFactoryKeyIds.filter((uniqId) =>
                      ownedUniqsIds.includes(uniqId),
                    ).length;
                    return tokenFactory.exclusiveAccess.requiredAmount > ownedAmount;
                  }
                  return tokenFactory.exclusiveAccess?.uniqFactoryKeyIds.some(
                    (uniqId: string) => !ownedUniqsIds.includes(uniqId),
                  );
                }),
              ),
            ),
          ),
        ),
        of(false),
      ),
    ),
    startWith(true),
  );

  constructor(
    private cd: ChangeDetectorRef,
    private deepLinkService: DeepLinkService,
    private shareUrlService: ShareUrlService,
    private featureFlagService: FeatureFlagService,
    private tokenFactoryPreviewFacadeService: TokenFactoryPreviewFacadeService,
  ) {
    super();
  }

  ngOnInit() {
    this.refreshBreadcrumbs();
    /**
     * Get the resalable label from the service when at least one token factory is resalable.
     * When both observables emit, we check if the resalable token factory is in the token list.
     * We use the resalable token factory to display the resalable label in the template.
     */
    combineLatest([this.tokenFactorySubject$, this.tokenFactoryPreviewFacadeService.resalableTokenFactory$])
      .pipe(
        filter(([tokenFactories, resalableTokenFactory]) => tokenFactories.length > 0 && !!resalableTokenFactory),
        filter(([tokenFactories, resalableTokenFactory]) =>
          tokenFactories.some((token) => token?.onChainId?.toString() === resalableTokenFactory.onChainId),
        ),
        map(([_, resalableTokenFactory]) => resalableTokenFactory),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe((resalableToken: IdCardEntity) => {
        this.resalableToken = resalableToken;
        this.cd.markForCheck();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tokenFactoriesByType) {
      this.resalableToken = undefined;
      this.setTokenFactory();
      if (changes.tokenFactoriesByType.currentValue?.game?.length) {
        this.gameTokenFactories$.next(this.tokenFactoriesByType.game);
      }
    }

    if (changes.game) {
      this.refreshBreadcrumbs();
      this.gameUrl = this.featureFlagService.isEnabled(FeatureFlag.PUBLIC_GAME_STORE)
        ? this.shareUrlService.getSharableUrl()
        : this.deepLinkService.buildProxyDeeplinkURL(AppId.ULTRA_GAMES, `store${this.game.getUrl()}`);
    }
  }

  isMoreThanOneBuyingOption(): boolean {
    return this.tokenFactoriesByType ? Object.values(this.tokenFactoriesByType)?.flat(1)?.length > 1 : false;
  }

  isContainFreeToken(): boolean {
    return this.tokenFactoriesByType
      ? Object.values(this.tokenFactoriesByType)
          .flat(1)
          .some((option: ITokenFactory) => option?.freeToPlay)
      : false;
  }

  getMinimumOptionPrice(): ITokenPrice {
    const prices: ITokenPrice[] = [];

    Object.values(this.tokenFactoriesByType)
      .flat(1)
      .forEach((option) => {
        prices.push(...option.livePrices);
      });

    return prices.sort((a, b) => (a.amount > b.amount ? 1 : -1))[0];
  }

  goToOptions(): void {
    this.scrollToOptions.emit();
  }

  private refreshBreadcrumbs() {
    this.breadCrumbs = [
      { title: 'Game Store', link: '/', dataId: 'game-store-page' },
      { title: this.game?.title, dataId: 'game-detail-page' },
    ];
  }

  /**
   * click on add / rm btn to wishlist
   * @param {boolean} include - flag, need game remove or add
   */
  toggleGameInWishList(include: boolean): void {
    this.toggleWishList.emit(include);
  }

  buyGame(): void {
    this.openShoppingCart.emit([this.game, this.tokenFactory]);
  }

  animateNotification(): void {
    this.triggerTooltip = !this.triggerTooltip;
  }

  getDescriptionForSharingBtn(game: GameDetail): string {
    return `${game.title} — ${game.descriptionShort}`;
  }

  isTokenFactoryAlreadyInTheUserLibrary(): boolean {
    if (this.tokenFactory) {
      return this.game.isTokenFactoryAlreadyInTheUserLibrary(this.tokenFactory.id);
    }
    return false;
  }

  isGameBuyable(): boolean {
    return this.game.isGameBuyable();
  }

  private setTokenFactory() {
    // Validation: Check if there is a buyableTokenFactory if not tries to find a freeToPlay game token
    // to be assigned as the game to be displayed in the header
    const game = this.tokenFactoriesByType?.game;
    // todo: iterate through game factories to get the cheaper game and use it as tokenFactory
    this.tokenFactory = game?.find((token) => token.id === this.game.buyableTokenFactory?.id);
    if (!this.tokenFactory) {
      this.tokenFactory = game?.find((token) => token.freeToPlay);
    }

    if (game) {
      this.tokenFactorySubject$.next(game);
    }
  }
}
