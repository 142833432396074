import { Directive, HostListener, Input } from '@angular/core';

import { DialogRef } from '../providers/dialog-ref';

@Directive({
  selector: '[ultra-dialog-close], [ultraDialogClose]',
})
export class DialogCloseDirective {
  @Input('ultraDialogClose')
  data: any;

  constructor(private dialogRef: DialogRef<any>) {}

  @HostListener('click')
  public onClick() {
    this.dialogRef.close(this.data);
  }
}
