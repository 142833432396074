import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';

import { ITokenPrice } from '../../../../models/token-factory';
import { DisplayPrices } from '../../../../utils';
import { BtnSize, PriceTheme } from '../../helpers/helper.enums';

@Component({
  selector: 'ultra-game-price',
  templateUrl: './game-price.component.html',
  styleUrls: ['./game-price.component.scss'],
  providers: [DecimalPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GamePriceComponent extends DisplayPrices {
  @Input()
  isFree = false;
  @Input()
  btnSize: BtnSize = BtnSize.lg;
  @Input()
  theme: PriceTheme = PriceTheme.light;
  @Input()
  get price(): ITokenPrice {
    return this._price;
  }

  set price(val: ITokenPrice) {
    this._price = val;
  }

  constructor(protected decimalPipe: DecimalPipe) {
    super(decimalPipe);
  }

  get settings(): ITokenPrice {
    return {
      amount: this._price.amount,
      symbol: this._price.symbol,
    };
  }

  private _price: ITokenPrice;
  @ContentChild(TemplateRef)
  template;
}
