import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ImgSize } from '../../../../lazy-image/components/lazy-image/lazy-image.helper';

@Component({
  selector: 'ultra-game-item-slideshow-image',
  templateUrl: 'game-item-slideshow-image.component.html',
  styleUrls: ['./game-item-slideshow-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * A component that shows an image inside the slideshow
 */
export class GameItemSlideshowImageComponent {
  @Input() videoPreview = false;
  @Input() isActive = false;
  @Input() imageSize: ImgSize = 's';
  @Input() defaultImage = '';
  @Input() styleClass = '';
}
