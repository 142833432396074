import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ultra-navbar-search-link-icon',
  templateUrl: './navbar-search-link-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarSearchLinkIconComponent {
  @Input() link: string;
  @Input() label: string;
}
