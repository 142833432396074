<ul class="navbar-nav">
  @for (tab of tabs; track trackByLabel($index, tab)) {
    <li>
      <a
        class="navbar-nav__link d-flex align-items-center {{ tab.className || '' }}"
        [class.flex-row-reverse]="isRowReverse(tab)"
        [routerLink]="[tab.url]"
        routerLinkActive="navbar-nav__link--active"
        [routerLinkActiveOptions]="{ exact: tab.exactMatch || false }"
        ultraAutotestAttribute="navbar-link-{{ tab.url }}">
        @if (tab.icon) {
          <i class="icon icon-{{ tab.icon.type }}"></i>
        }
        <span [attr.i18n]="[tab.i18nLabel || tab.label]">{{ tab.label }}</span>
      </a>
    </li>
  }
</ul>
