import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ScrollDirective } from '../../directives/scroll/scroll.directive';
import { GameModule } from '../game/game.module';
import { ImageResizeModule } from '../image-resize/image-resize.module';
import { LazyImageModule } from '../lazy-image/lazy-image.module';
import { LimitTextModule } from '../limit-text/limit-text.module';
import { PlaceholderLoadingModule } from '../placeholder-loading/placeholder-loading.module';
import { SliderComponent } from '../slider';
import { SliderContentDirective } from '../slider/slider-content.directive';
import { SwiperTemplateDirective } from '../slider/slider-template.directive';

import { GameItemSlideshowComponent } from './components/game-item-slideshow/game-item-slideshow.component';
import { GameItemSlideshowImageComponent } from './components/game-item-slideshow/game-item-slideshow-image/game-item-slideshow-image.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SliderComponent,
    SliderContentDirective,
    SwiperTemplateDirective,
    GameModule,
    PlaceholderLoadingModule,
    LimitTextModule,
    LazyImageModule,
    ImageResizeModule,
    ScrollDirective,
  ],
  declarations: [GameItemSlideshowComponent, GameItemSlideshowImageComponent],
  exports: [GameItemSlideshowComponent, GameItemSlideshowImageComponent],
})
export class GamesSlideshowModule {}
