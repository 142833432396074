import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { EllipsisModule } from 'ngx-ellipsis';

import { AutotestAttributeDirective } from '../../autotests';
import { SharedPipesModule } from '../../pipes/pipes.module';

import { BreadcrumbsComponent } from './breadcrumbs.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AutotestAttributeDirective,
    SharedPipesModule,
    EllipsisModule,
    NgbTooltipModule,
  ],
  declarations: [BreadcrumbsComponent],
  exports: [BreadcrumbsComponent],
})
export class BreadcrumbsModule {}
