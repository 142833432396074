import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { IFilters, IPaginationFilters } from '../../base-interfaces/base-filter.interface';

@Injectable()
export class GameFiltersService {
  private filters: IPaginationFilters;
  private pageSize = 15;
  private gamesFilters: BehaviorSubject<IPaginationFilters>;

  constructor() {
    this.filters = {
      page: 1,
      pagination: {
        skip: 0,
        limit: this.pageSize,
      },
    };
    this.gamesFilters = new BehaviorSubject(this.filters);
  }

  listenGamesFilters(): Observable<IPaginationFilters> {
    return this.gamesFilters.asObservable();
  }

  getCurrentFilter(name: string): string | number {
    return this.filters.filter[name] || null;
  }

  onSearchGames(search: string): void {
    this.filters.filter = {
      ...this.filters.filter,
      search,
    };
    this.clearPageOffset();
    this.propagateChanges();
  }

  onChangeFilter(filters: IFilters): void {
    for (const name in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, name)) {
        if (!filters[name] || (Array.isArray(filters[name]) && filters[name].length === 0)) {
          delete this.filters.filter[name];
        } else {
          this.filters.filter = {
            ...this.filters.filter,
            ...{ [name]: filters[name] },
          };
        }
      }
    }
    this.clearPageOffset();
    this.propagateChanges();
  }

  onPageChange(page: number): void {
    this.filters.pagination.skip = this.pageSize * page - this.pageSize;
    this.filters.page = page;

    this.propagateChanges();
  }

  private propagateChanges(): void {
    this.gamesFilters.next(this.filters);
  }

  private clearPageOffset(): void {
    this.filters.pagination.skip = 0;
    this.filters.page = 1;
  }
}
