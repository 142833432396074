import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'downloadSpeed',
  standalone: true,
})
export class DownloadSpeedPipe implements PipeTransform {
  transform(value: any): string {
    return `${Math.abs(+value / (1024 * 1024)).toFixed(2)} MB/s`;
  }
}
