import { Injectable, TemplateRef } from '@angular/core';

import { Dialog } from '../../dialog/providers/dialog.service';
import { DialogRef } from '../../dialog/providers/dialog-ref';
import { ConfirmationComponent } from '../components/confirmation.component';
import { ConfirmationConfig } from '../confirmation.interface';

@Injectable()
export class ConfirmationService {
  constructor(private dialog: Dialog) {}

  public openConfirmationDialog(data: ConfirmationConfig, templateRef: TemplateRef<any> = null): DialogRef<any> {
    return this.dialog.open(templateRef || ConfirmationComponent, { data });
  }
}
