import { ChangeDetectionStrategy, Component, Inject, TemplateRef } from '@angular/core';

import { DIALOG_DATA } from '../../dialog/providers/dialog-injectors';
import { DialogRef } from '../../dialog/providers/dialog-ref';
import { ConfirmationConfig } from '../confirmation.interface';

type ConfirmationContentState = 'default' | 'custom' | 'template';

@Component({
  selector: 'ultra-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationComponent {
  constructor(@Inject(DIALOG_DATA) public data: ConfirmationConfig, private dialogRef: DialogRef<any>) {}

  public getContentState(prop: string | TemplateRef<any>): ConfirmationContentState {
    if (!prop) {
      return 'default';
    }
    if (prop && this.isTemplateRef(prop)) {
      return 'template';
    }
    return 'custom';
  }

  private isTemplateRef(prop: string | TemplateRef<any>) {
    return prop instanceof TemplateRef;
  }
}
