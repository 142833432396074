import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { delay } from 'rxjs/operators';

import { MixpanelEvent, MixpanelService } from '@ultra/core/services';

import { getGameEventData } from '../../../../helpers/mixpanel-game-data.helper';
import { TokenStatus } from '../../../../models/game/enums/token-status.enum';
import { GameDetail } from '../../../../models/game/models/game-detail.model';
import { IImageDetail, IMultimedia, IVideo, VideoProvider } from '../../../../models/multimedia/interfaces';
import {
  PlayerVars,
  YoutubePlayerService,
} from '../../../../modules/youtube-player/services/youtube-player/youtube-player.service';
import { TokenPrices } from '../../../../utils/token-prices';
import { ISlideshowConfig } from '../../slideshow-config.interface';

@Component({
  selector: 'ultra-game-item-slideshow',
  templateUrl: './game-item-slideshow.component.html',
  styleUrls: ['./game-item-slideshow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameItemSlideshowComponent extends TokenPrices implements OnChanges, AfterViewInit, OnDestroy {
  @Input() game: GameDetail;

  @Input() config: ISlideshowConfig;

  @Input() autoplay: boolean;

  @ViewChild('gameDescription') gameDescription: ElementRef;

  public currentPreviewVideo: IVideo = null;
  public currentPreviewImage: any = null; // Todo refactoring it

  hollowBoxes: number[] = [0, 1, 2, 3, 4, 5];
  tokenStatus = TokenStatus;

  private youtubePlayer: any;
  private imagePreviewTimeout: any;

  constructor(
    private mixpanelService: MixpanelService,
    private youtubePlayerService: YoutubePlayerService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['game'] && !changes['game'].isFirstChange()) {
      this.setPreview();
    }
  }

  ngAfterViewInit() {
    this.setPreview();
  }

  ngOnDestroy() {
    if (this.youtubePlayer?.stopVideo) {
      this.youtubePlayer.destroy();
    }
  }

  public getImgBySize(images: IImageDetail[]) {
    return this.game.getImgBySize(images);
  }

  // Todo refactoring
  setCurrentPreviewImage(preview: any): void {
    this.currentPreviewVideo = null;
    this.currentPreviewImage = preview;
    this.stopVideo();
    this.cd.detectChanges();

    if (this.autoplay) {
      this.changeImageHandler(this.game.multimedia);
    }
  }

  setCurrentPreviewVideo(preview: IVideo): void {
    if (this.currentPreviewVideo === preview) {
      return;
    }

    if (this.imagePreviewTimeout) {
      clearInterval(this.imagePreviewTimeout);
    }

    this.currentPreviewImage = null;
    this.currentPreviewVideo = preview;
    this.playVideo(preview);
  }

  onViewGamePageClick() {
    this.mixpanelService.track(MixpanelEvent.POPULAR_GAMES_CLICK, getGameEventData(this.game));
  }

  private loadYoutubePlayer(preview: IVideo): void {
    if (this.youtubePlayer) {
      this.youtubePlayer.destroy();
      this.youtubePlayer = null;
    }

    const youtubePlayerParams = this.youtubePlayerService.getDefaultParams();
    if (this.autoplay) {
      Object.assign(youtubePlayerParams.playerVars, this.youtubePlayerService.getAutoplayParams().playerVars);
    }
    youtubePlayerParams.videoId = preview.videoId;
    this.createPlayer(youtubePlayerParams);
  }

  private createPlayer(params: PlayerVars): void {
    this.youtubePlayerService
      .onReady()
      .pipe(delay(10))
      .subscribe({
        next: (YT: any) => {
          this.youtubePlayer = new YT.Player(`video-player-${this.game.id}`, params);
          this.cd.detectChanges();

          if (this.autoplay) {
            this.youtubePlayer.addEventListener('onStateChange', this.playerEventHandler.bind(this));
          }
        },
        error: (err) => console.error(err),
      });
  }

  private playerEventHandler(state: any) {
    if (state.data === 0) {
      const index = this.game.multimedia.videosPreviews.indexOf(this.currentPreviewVideo);

      if (this.game.multimedia.videosPreviews.length - 1 === index) {
        this.setCurrentPreviewImage(this.game.multimedia.imageGalleryList[0]);
      } else {
        this.setCurrentPreviewVideo(this.game.multimedia.videosPreviews[index + 1]);
      }
    }
  }

  private changeImageHandler(multimedia: IMultimedia) {
    if (this.imagePreviewTimeout) {
      clearTimeout(this.imagePreviewTimeout);
    }

    this.imagePreviewTimeout = setTimeout(() => {
      const index = multimedia.imageGalleryList.indexOf(this.currentPreviewImage);

      if (multimedia.imageGalleryList.length - 1 === index) {
        this.setCurrentPreviewVideo(multimedia.videosPreviews[0]);
      } else {
        this.setCurrentPreviewImage(multimedia.imageGalleryList[index + 1]);
      }
    }, 8000);
  }

  private playVideo(preview: IVideo): void {
    if (preview && preview.provider.toLocaleLowerCase() === VideoProvider.Youtube.toLocaleLowerCase()) {
      this.loadYoutubePlayer(preview);
    }
  }

  private stopVideo(): void {
    if (this.youtubePlayer?.stopVideo) {
      this.youtubePlayer.stopVideo();
    }
  }

  private setPreview() {
    if (this.game.multimedia) {
      if (this.game.multimedia.videosPreviews?.length && this.autoplay) {
        this.setCurrentPreviewVideo(this.game.multimedia.videosPreviews[0]);
      } else {
        this.setCurrentPreviewImage(this.game.multimedia.imageGalleryList[0]);
      }
    }
  }
}
