import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG, IEnvironment } from '@ultra/environment';

@Injectable({ providedIn: 'root' })
export class LinkHelper {
  constructor(@Inject(APP_CONFIG) private readonly environment: IEnvironment) {}

  public legalTermsLink(): string {
    return `${this.environment.settingsUrl}/profile/legal`;
  }

  public getBraincloudDocUrl(): string {
    return `${this.environment.braincloudDocUrl}`;
  }
}
