@if (game) {
  <!-- Game Background -->
  <div class="game-detail-background w-100" *contentSkeleton="isRouteChange; theme: placeholderTheme.GAME_HERO_IMAGE">
    <ultra-lazy-image
      class="bg-item position-absolute w-100 h-100"
      [srcset]="game.getBgResponsiveList()"
      styleClass="full-width"
      [defaultImage]="game.getBgImg()"
      imageSize="l" />
  </div>
  <div class="container game-detail-container mb-6">
    <!-- Breadcrumbs -->
    <div class="row position-relative z-index-1">
      <div class="col d-flex align-items-center justify-content-center flex-column mt-5 w-100">
        <div
          class="game-detail-container__breadcrumbs"
          *contentSkeleton="isRouteChange; className: ['my-3', 'rounded-5']; maxWidth: '256px'; height: '36px'">
          <ultra-breadcrumbs [breadCrumbs]="breadCrumbs" />
        </div>
      </div>
    </div>
    <!-- Game Poster -->
    <div class="row">
      <div class="col d-flex align-items-center justify-content-center flex-column w-100">
        <div class="game-detail-container__poster rounded">
          <ultra-lazy-image
            *contentSkeleton="isRouteChange; isAnimated: true"
            [defaultImage]="game.getBoxArtImage()"
            styleClass="rounded"
            [imageAlt]="game.getBoxArtImageName()">
            <div class="overlay w-100 h-100"></div>
          </ultra-lazy-image>
        </div>
      </div>
    </div>
    <!-- Game Description -->
    <div class="row">
      <div
        class="col d-flex align-items-center justify-content-center flex-column mt-3 w-100"
        *contentSkeleton="
          isRouteChange;
          className: ['my-3', 'mx-auto', 'rounded-5'];
          maxWidth: '256px';
          height: '44px'
        ">
        <h1 class="game-detail-container__name text-center" ultraAutotestAttribute="game-details-title">
          {{ game.title }}
        </h1>
        <ul class="d-flex flex-wrap game-detail-container__tags">
          @for (category of game.categories; track category; let last = $last) {
            <li class="u-badge u-badge-ultra-purple-light me-2 mb-2 badge-tag b2-regular" [class.me-2]="!last">
              <i class="icon {{ category | genreIcon }}"></i>
              {{ category }}
            </li>
          }
        </ul>
      </div>
    </div>
    <!-- Resalable label -->
    <div class="row">
      <div
        class="d-flex justify-content-center w-100 ps-2"
        *contentSkeleton="
          isRouteChange;
          className: ['my-3', 'mx-auto', 'rounded-5'];
          maxWidth: '128px';
          height: '32px'
        ">
        @if (resalableToken?.tradingPeriod.resaleAvailability) {
          <ultra-actions-status
            class="d-flex justify-content-center mb-3 w-100 ps-2"
            [period]="resalableToken.tradability.tradingPeriod"
            [showExplicit]="true"
            customText="Resalable"
            [action]="actions.TRADABILITY"
            [availability]="resalableToken.tradability.tradingPeriod?.resaleAvailability"
            [showTooltip]="false" />
        }
      </div>
    </div>
    <!-- Game Short Description -->
    <div class="row">
      <div class="col mb-3">
        <p
          class="game-detail-container__short-description"
          ultraAutotestAttribute="game-details-short-description"
          *contentSkeleton="isRouteChange; className: ['mx-auto', 'rounded-5']; maxWidth: '640px'; height: '72px'">
          {{ game.descriptionShort }}
        </p>
      </div>
    </div>
    <!-- Game Price Button -->
    <div
      class="d-flex flex-wrap align-items-center justify-content-center"
      *contentSkeleton="isRouteChange; className: ['my-3', 'mx-auto', 'rounded-5']; maxWidth: '128px'; height: '32px'">
      <div class="bd-highlight pt-2 pb-1">
        @if ({ gameTokenFactories: gameTokenFactories$ | async }; as ctx) {
          @if (tokenFactory?.freeToPlay || game.getTokenPrices() !== null) {
            <div class="d-lg-flex justify-content-lg-between align-items-end pt-2 pt-md-0 mb-6">
              <div class="d-flex flex-wrap mb-3 mb-lg-0 order-2">
                <div
                  class="prices__wrapper align-items-center d-inline-flex flex-wrap justify-content-center justify-content-sm-start mb-1">
                  @if (!preview && !game.gameInLibrary && !isMoreThanOneBuyingOption()) {
                    @if (ctx.gameTokenFactories && !tokenFactory?.freeToPlay && getTokenPrices(game)?.discount > 0) {
                      <ultra-game-discount [discountBasis]="getTokenPrices(game)?.discount" class="h-100" />
                    }
                    @if (
                      !isTokenFactoryAlreadyInTheUserLibrary() &&
                      (tokenFactory?.freeToPlay || getTokenPrices(game)) &&
                      ctx.gameTokenFactories
                    ) {
                      <button
                        class="game-detail-container__button btn btn-md btn-primary btn-buy-now align-self-end px-1"
                        [class.game-detail-container__button--currency]="
                          tokenFactory?.prices && !tokenFactory?.freeToPlay
                        "
                        [class.game-detail-container__button--free]="tokenFactory?.freeToPlay"
                        [disabled]="gameBuyIsNotAllowed$ | async"
                        (click)="buyGame()"
                        ultraAutotestAttribute="game-details-price">
                        @if (tokenFactory?.freeToPlay) {
                          <span i18n>Free</span>
                        } @else {
                          @if (getTokenPrices(game)?.discount > 0) {
                            <ultra-game-price-discount [price]="getTokenPrices(game)" />
                          } @else {
                            <span>{{ getTokenPrices(game)?.amount | currency: getTokenPrices(game)?.symbol }}</span>
                          }
                        }
                      </button>
                    } @else if (!isTokenFactoryAlreadyInTheUserLibrary()) {
                      <ng-container
                        *ngTemplateOutlet="
                          notAvailableTemplate;
                          context: { gameTokenFactories: ctx.gameTokenFactories }
                        " />
                    }
                  }
                  @if (!preview && !game.gameInLibrary && isMoreThanOneBuyingOption()) {
                    @if (
                      !isTokenFactoryAlreadyInTheUserLibrary() && (isContainFreeToken() || getMinimumOptionPrice())
                    ) {
                      <button
                        class="game-detail-container__button btn btn-md btn-primary btn-buy-now align-self-end px-1 {{
                          isContainFreeToken()
                            ? 'game-detail-container__button--from-free'
                            : 'game-detail-container__button--from-price'
                        }}"
                        [class.game-detail-container__button--currency]="
                          tokenFactory?.prices && !tokenFactory?.freeToPlay
                        "
                        [disabled]="gameBuyIsNotAllowed$ | async"
                        (click)="goToOptions()">
                        @if (isContainFreeToken()) {
                          <span i18n>Free</span>
                        } @else {
                          <span>
                            <ng-container i18n>From </ng-container>
                            {{ getMinimumOptionPrice()?.amount | currency: getTokenPrices(game)?.symbol }}
                          </span>
                        }
                      </button>
                    }
                  }
                  @if (
                    (isGameBuyable() || tokenFactory?.freeToPlay) && isTokenFactoryAlreadyInTheUserLibrary() && !preview
                  ) {
                    <a class="btn btn-md btn-primary align-self-end px-2" [routerLink]="['/your-library']">
                      <ng-container i18n>Game in Your Library</ng-container>
                    </a>
                  }
                  @if (favoritePendingStore$ | async; as favoritePendingStore) {
                    @if (!loading || preview) {
                      <ultra-toggle-button
                        class="d-inline-flex"
                        [pending]="favoritePendingStore[game.id]"
                        [isSelected]="game.favorite"
                        (toggleValue)="toggleGameInWishList(!game.favorite)" />
                    }
                  }
                  <ultra-copy-to-clipboard
                    class="d-inline-flex"
                    [text]="gameUrl"
                    copyTooltipText="Copy Game URL"
                    i18n-copyTooltipText
                    copiedTooltipText="URL Copied"
                    mainIconType="icon-share-sm"
                    variant="transparent"
                    hoverIconType="icon-share-sm"
                    buttonClassNames="p-0"
                    wrapperClassNames="w-100"
                    [showLabel]="false"
                    i18n-copiedTooltipText />
                </div>
              </div>
            </div>
          } @else if (!isTokenFactoryAlreadyInTheUserLibrary()) {
            <ng-container
              *ngTemplateOutlet="notAvailableTemplate; context: { gameTokenFactories: ctx.gameTokenFactories }" />
          }
        }
      </div>
    </div>
  </div>
}

<ng-template #notAvailableTemplate let-gameTokenFactories="gameTokenFactories">
  <div class="ms-auto text-center f-size-12 text-capitalize">
    @if (
      (game.buyableTokenFactory && game.buyableTokenFactory.prices === null) ||
      (!gameTokenFactories && hasGeofencingRestriction)
    ) {
      <span class="d-inline-block no-token-message w-100 text-center pe-2" i18n>
        This content is not available in your region
      </span>
    } @else if (!game.buyableTokenFactory && game.getReleaseDateObject()) {
      <!--<span class="d-inline-block no-token-message w-100 text-center pe-2">
        <ng-container i18n>Available:</ng-container>
        {{ game.getReleaseDateObject() | date: game.getReleaseDateFormat(DatePipeFormat.DayMonthYearFullText) }}
      </span>-->
    } @else if (!game.getReleaseDateObject()) {
      <!--<button class="game-detail-container__button btn btn-lg btn-primary px-1 me-2" disabled>
        <ng-container i18n>Coming Soon</ng-container>
      </button>-->
    }
  </div>
</ng-template>
