import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutotestAttributeDirective } from '../../autotests';

import { FooterComponent } from './components/footer/footer.component';

@NgModule({
  declarations: [FooterComponent],
  exports: [FooterComponent],
  imports: [CommonModule, AutotestAttributeDirective],
})
export class FooterModule {}
