import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'ultra-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFormComponent {
  private searchChanged: Subject<string> = new Subject<string>();
  searchInput: string;

  @Input()
  placeholder = 'Search...';
  @Input()
  expandable = false;
  @Input()
  additionalClass: string[] | string = [];
  @Input()
  disabled = false;
  @Output()
  search: EventEmitter<string> = new EventEmitter<string>();
  @Input()
  set resetInput(resetInput: boolean) {
    this.searchInput = resetInput ? '' : this.searchInput;
  }

  @Input()
  emitFocusEvent = false;
  @Input()
  dataId = '';

  constructor() {
    this.startListenSearchField();
  }

  changed(text: string): void {
    this.searchChanged.next(text);
  }

  private startListenSearchField(): void {
    let flagSearch = true;
    let flagReset = true;

    this.searchChanged.pipe(debounceTime(300), untilDestroyed(this)).subscribe((value) => {
      if (value.trim().length > 1) {
        flagSearch = false;
        flagReset = true;
        this.search.emit(value);
      } else if (!flagSearch && flagReset) {
        flagReset = false;
        this.search.emit(null);
      }
    });
  }

  onFocus() {
    if (this.emitFocusEvent && this.searchInput === '') {
      this.search.emit(this.searchInput);
    }
  }
}
