import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

import { ToastService } from './toast.service';

@Component({
  selector: 'ultra-toast-root',
  template: `
    @for (toast of toastService.toasts$ | async; track toast) {
      <ngb-toast
        [class]="toast.classname"
        [autohide]="true"
        [delay]="toast.delay"
        (hidden)="toastService.removeToast(toast)">
        @if (toast.template) {
          <ng-template [ngTemplateOutlet]="toast.template"></ng-template>
        } @else {
          {{ toast.text }}
        }
      </ngb-toast>
    }
  `,
  imports: [NgbToastModule, NgTemplateOutlet, AsyncPipe],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastRootComponent {
  toastService = inject(ToastService);
}
