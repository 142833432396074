import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WINDOW } from '@ultra/core/providers';
import { APP_CONFIG, IEnvironment } from '@ultra/environment';

import { FreeTokenFactoryParamsEnum } from './enums/free-token-factory-params.enum';

@Injectable({
  providedIn: 'root',
})
export class FreeToPlayParamsService {
  public gameId$: Observable<string>;
  public tokenFactoryId$: Observable<string>;
  public cancelUrl$: Observable<string>;

  constructor(
    @Inject(APP_CONFIG) private readonly environment: IEnvironment,
    @Inject(WINDOW) private window: Window,
    private activatedRoute: ActivatedRoute
  ) {
    this.gameId$ = this.activatedRoute.queryParams.pipe(map((params) => params[FreeTokenFactoryParamsEnum.GAME_ID]));
    this.tokenFactoryId$ = this.activatedRoute.queryParams.pipe(
      map((params) => params[FreeTokenFactoryParamsEnum.TOKEN_FACTORY_ID])
    );
    this.cancelUrl$ = this.activatedRoute.queryParams.pipe(
      map((params) => params[FreeTokenFactoryParamsEnum.CANCEL_URL])
    );
  }

  openApp(gameId: string, tokenId: string, cancelUrl: string) {
    const route = new URL(`${this.environment.transactionUrl}/free-game`);
    route.searchParams.set(FreeTokenFactoryParamsEnum.GAME_ID, gameId);
    route.searchParams.set(FreeTokenFactoryParamsEnum.TOKEN_FACTORY_ID, tokenId);
    route.searchParams.set(FreeTokenFactoryParamsEnum.CANCEL_URL, cancelUrl);
    this.window.location.assign(route.href);
  }
}
