import { ITokenPrice } from '../../../models/token-factory/interfaces/token-price.interface';

export interface IBasketItem {
  type: BasketItemType;
  tokenType: string;
  id: string;
  name: string;
  subName: string;
  creatorName: string;
  creatorVerified: boolean;
  imageUrl: string;
  price: ITokenPrice;
  metadataStatus?: string;
}

export enum BasketItemType {
  TOKEN_FACTORY = 'tokenFactory',
  UNIQ_FACTORY = 'uniqFactory',
}

export interface IStorageBasketItem {
  gameId: string;
  tokenId: string;
}
