import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

import { IOrderByFilter } from '../../../../models/game/interfaces/order-by-filter.interface';

@Component({
  selector: 'ultra-orderby',
  templateUrl: './orderby.component.html',
  styleUrls: ['./orderby.component.scss'],
  providers: [NgbDropdownConfig],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderbyComponent implements OnInit {
  @Input()
  currentValue: IOrderByFilter;
  @Input()
  values: IOrderByFilter[];
  @Input()
  additionalClass = '';
  @Output()
  orderByChange: EventEmitter<IOrderByFilter> = new EventEmitter();

  constructor(config: NgbDropdownConfig) {
    config.placement = 'bottom-right';
  }

  ngOnInit() {
    this.currentValue = this.currentValue || this.values?.[0];
  }

  onSelectValue(value: IOrderByFilter) {
    this.currentValue = value;
    this.orderByChange.emit(value);
  }
}
