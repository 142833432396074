import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { AutotestAttributeDirective } from '../../autotests';
import { ScrollDirective } from '../../directives/scroll/scroll.directive';

import { DefaultModalComponent } from './components/default-modal/default-modal.component';

@NgModule({
  imports: [CommonModule, NgbModalModule, AutotestAttributeDirective, ScrollDirective],
  declarations: [DefaultModalComponent],
  exports: [DefaultModalComponent],
})
export class DefaultModalModule {}
