import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, distinctUntilChanged, map, Observable, of, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';

import { getGraphQLErrorMessage } from '@ultra/core/helpers';

import { GameAlgoliaService } from '../../../../game/algolia/game-algolia.service';
import { GameList } from '../../../../models/base-interfaces/game-requests.interfaces';
import { Game } from '../../../../models/game/models/game.model';
import { ToastService } from '../../../toast-root';

@Injectable({ providedIn: 'root' })
export class NavbarSearchFacadeService {
  private gameAlgoliaService = inject(GameAlgoliaService);
  private toastService = inject(ToastService);
  private router = inject(Router);

  searchChanged: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  results$: Observable<Game[]> = this.searchChanged.pipe(
    distinctUntilChanged(),
    filter(Boolean),
    switchMap((searchValue: string) =>
      this.gameAlgoliaService
        .getAllGames$({
          filter: {
            search: searchValue,
            orderBy: 'RELEVANCE',
            withFiltersCount: true,
          },
          pagination: { skip: 0, limit: 15 },
        })
        .pipe(
          catchError((e) => {
            const errorMessage = getGraphQLErrorMessage(e);
            this.toastService.danger({
              text:
                errorMessage === 'Request Timeout'
                  ? 'Search query took too long, please refine your search or try again later'
                  : errorMessage,
              classname: 'bottom-0 start-50 toast toast-container translate-middle position-fixed',
            });
            return of([]);
          }),
        ),
    ),
    map((games: GameList) => games.nodes),
  );

  navigateToSearchPage(searchValue: string): void {
    this.router.navigate(['/game-search'], {
      queryParams: {
        q: searchValue,
      },
    });
    this.searchChanged.next(null);
  }
}
