import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';

import { NavbarTab } from '../../interfaces';

@Component({
  selector: 'ultra-navbar-nav',
  templateUrl: './navbar-nav.component.html',
  styleUrls: ['./navbar-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarNavComponent {
  constructor(public router: Router, @Inject(DOCUMENT) private document) {}

  @Input() tabs?: NavbarTab[];

  trackByLabel(index: number, item: NavbarTab): string {
    return item.label;
  }

  isRowReverse(tab: NavbarTab): boolean {
    return tab.icon?.position === 'end';
  }
}
