import { CURRENCY_LIST_QUERY } from '../gql/currencies/queries/currency-list.query';
import { CART_GAMES_QUERY } from '../gql/game';
import { EXCHANGE_MARGIN_QUERY, EXCHANGE_RATE_QUERY, EXCHANGE_RATE_SUBSCRIPTION, STATES_QUERY } from '../gql/other';
import { REDEEM_CODE } from '../gql/redeem-code';
import {
  GET_FULL_UNIQ_QUERY,
  GET_UNIQ_FOR_SALE_QUERY,
  GET_UNIQ_QUERY,
  INVENTORY_LIST_FILTER_QUERY,
  INVENTORY_LIST_QUERY,
  UNIQ_FOR_SALE_FEE_QUERY,
} from '../gql/uniq';
import { USER_GAME_QUERY } from '../gql/user-game';
import { UNIQ_FACTORIES_QUERY, UNIQ_FACTORY_WITH_REVENUE_QUERY, UNIQ_SALE_FEE_QUERY } from '../uniq-factory/graphql';

export class ApiSharedGqlConfig {
  // ********** Queries **********
  static readonly INVENTORY_LIST = INVENTORY_LIST_QUERY;
  static readonly UNIQ_FOR_SALE_FEE = UNIQ_FOR_SALE_FEE_QUERY;
  static readonly INVENTORY_LIST_FILTER = INVENTORY_LIST_FILTER_QUERY;
  static readonly UNIQ_BY_ID = GET_FULL_UNIQ_QUERY;
  static readonly GET_UNIQ_QUERY = GET_UNIQ_QUERY;

  static readonly STATE_LIST = STATES_QUERY;
  static readonly CART_GAMES = CART_GAMES_QUERY;

  static readonly CURRENCY_LIST = CURRENCY_LIST_QUERY;
  static readonly EXCHANGE_MARGIN = EXCHANGE_MARGIN_QUERY;
  static readonly EXCHANGE_RATE = EXCHANGE_RATE_QUERY;
  static readonly EXCHANGE_RATE_SUB = EXCHANGE_RATE_SUBSCRIPTION;
  static readonly USER_LIBRARY_GAMES = USER_GAME_QUERY;
  static readonly UNIQ_FACTORIES_QUERY = UNIQ_FACTORIES_QUERY;
  static readonly UNIQ_FACTORY_WITH_REVENUE = UNIQ_FACTORY_WITH_REVENUE_QUERY;
  static readonly UNIQ_SALE_FEE = UNIQ_SALE_FEE_QUERY;
  static readonly GET_UNIQ_FOR_SALE = GET_UNIQ_FOR_SALE_QUERY;

  // ********** Mutations **********
  static readonly REDEEM_CODE = REDEEM_CODE;
}
