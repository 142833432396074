import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DialogModule } from '../dialog/dialog.module';

import { ConfirmationComponent } from './components/confirmation.component';
import { ConfirmationDirective } from './confirmation.directive';
import { ConfirmationService } from './providers/confirmation.service';

@NgModule({
  imports: [CommonModule, DialogModule],
  declarations: [ConfirmationComponent, ConfirmationDirective],
  providers: [ConfirmationService],
  exports: [ConfirmationDirective, ConfirmationComponent],
})
export class ConfirmationModule {}
