import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DirectivesModule } from '../../directives/directives.module';

import { CollapsibleNavComponent } from './components/collapsible-nav/collapsible-nav.component';

@NgModule({
  declarations: [CollapsibleNavComponent],
  exports: [CollapsibleNavComponent],
  imports: [CommonModule, DirectivesModule],
})
export class CollapsibleNavModule {}
