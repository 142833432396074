<nav class="navbar border-divider-bottom position-fixed px-md-2 px-lg-5 d-flex flex-nowrap align-items-center">
  <div class="navbar-tabs col col-md-4 px-col-gutter">
    @if (logoUrl) {
      <a class="me-3" routerLink="/">
        <img [src]="logoUrl" />
      </a>
    }
    @if (tabs) {
      <ultra-navbar-nav [tabs]="tabs"></ultra-navbar-nav>
    }
  </div>

  <div class="navbar-search col px-col-gutter">
    @if (showSearchGamesForm) {
      <ultra-navbar-search></ultra-navbar-search>
    }

    @if (showSearchLink) {
      <ultra-navbar-search-link
        [link]="searchLinkUrl"
        [linkParams]="searchLinkParams"
        [label]="searchLinkLabel"></ultra-navbar-search-link>
    }
  </div>

  <div class="navbar-buttons col col-md-4 px-col-gutter">
    @if (showSearchLink) {
      <ultra-navbar-search-link-icon
        [link]="searchLinkUrl"
        [label]="searchLinkLabel"
        class="navbar__item"></ultra-navbar-search-link-icon>
    }

    @if (showCart) {
      <ultra-navbar-cart *ultraFeatureFlag="FeatureFlag.SHOPPING_CART" class="navbar__item"></ultra-navbar-cart>
    }

    @if (showWallet) {
      <ultra-wallet-balance class="navbar__item ms-3"></ultra-wallet-balance>
    }

    @if (showDownload) {
      <a ultraButton class="btn-min-size-120 ms-3 d-none d-lg-flex" [href]="downloadUrl">Download</a>
    }
  </div>
</nav>
