import { Component, inject, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { Game } from '../../../../models';

import { NavbarSearchFacadeService } from './navbar-search-facade.service';

@Component({
  selector: 'ultra-navbar-search',
  templateUrl: './navbar-search.component.html',
  styleUrls: ['./navbar-search.component.scss'],
})
export class NavbarSearchComponent {
  @ViewChild(NgbDropdown, { static: false })
  private gamesDropdown: NgbDropdown;
  pending = false;
  isSearchBarReset = false;
  private navbarSearchFacadeService = inject(NavbarSearchFacadeService);

  games$: Observable<Game[]> = this.navbarSearchFacadeService.results$.pipe(
    tap(() => (this.pending = false)),
    tap(() => !this.gamesDropdown?.isOpen() && this.gamesDropdown?.open()),
    map((games) => (this.isSearchBarReset ? [] : games))
  );

  reset(): void {
    this.gamesDropdown?.close();
    this.isSearchBarReset = true;
    this.pending = false;
    this.navbarSearchFacadeService.searchChanged.next(null);
  }

  getRangeFromNextGames(): any[] {
    const max = 5;
    const items: number[] = [];

    for (let i = 1; i <= max; i++) {
      items.push(i);
    }

    return items;
  }

  getGames(search: string): void {
    if (this.navbarSearchFacadeService.searchChanged.value !== search) {
      this.pending = true;
      this.isSearchBarReset = false;
      this.navbarSearchFacadeService.searchChanged.next(search);
    }
  }

  navigateToSearchPage(searchValue: string): void {
    this.gamesDropdown.close();
    this.navbarSearchFacadeService.navigateToSearchPage(searchValue);
  }
}
