@if (tokenFactory(); as tokenFactory) {
  <ultra-id-card-header ultraAutotestAttribute="ultra-id-card-header">
    <div class="token-type-icon position-relative">
      <ultra-uniq-type-icon [type]="tokenFactory.onChainId ? 'game' : 'not-uniq'" showOrnament="true" opacity="70" />
    </div>
    <ultra-lazy-image
      class="id-card-image"
      [defaultImage]="tokenFactory.image?.images?.length ? tokenFactory.image.images[0]?.url : null"
      styleClass="rounded d-flex position-relative w-100 h-100" />

    <div class="f-size-12 text-white-alpha-5 text-capitalize" ultraAutotestAttribute="ultra-id-card-header-name">
      {{ gameName() }}
    </div>
    <span
      class="fw-bold f-size-16 text-capitalize text-white mt-1"
      ultraAutotestAttribute="ultra-id-card-header-factory-name"
      >{{ tokenFactory.name }}</span
    >
    <div class="d-flex align-items-center justify-content-start mt-1 text-white-alpha-5 caption-regular">
      @if (tokenFactory.contentType?.name) {
        <span class="text-capitalize" ultraAutotestAttribute="ultra-id-card-header-content-type-name">
          {{ tokenFactory.contentType.name }}
        </span>
      }
      <div class="px-1">•</div>
      <div>
        <span>by </span>
        <span class="text-capitalize" ultraAutotestAttribute="ultra-id-card-header-creator">{{ companyName() }}</span>
      </div>
    </div>

    @if (showPrice()) {
      <div class="d-flex align-items-center id-card-actions">
        @if (uniqFactoryIdCardEntity) {
          <ultra-preview-token-header-properties
            class="d-flex align-items-center px-3"
            [idCardEntity]="uniqFactoryIdCardEntity"
            (propertyClick)="propertyAccess.emit($event)" />
        }
        @if (isTokenFactoryAlreadyInLibrary()) {
          <a
            class="preview-price preview-price__purchased preview-price--{{ priceTheme() }} preview-price--{{
              btnSize()
            }} rounded d-flex-center"
            (click)="$event.stopPropagation()"
            routerLink="/your-library">
            <span ultraAutotestAttribute="preview-token-preview-price-purchased" i18n>Already Purchased</span>
          </a>
        } @else {
          <div class="d-flex gap-2 justify-content-center">
            @if (
              !tokenFactory.freeToPlay &&
              tokenFactory.livePrices?.[0]?.discount &&
              tokenFactory.livePrices[0].discount > 0
            ) {
              <ultra-game-discount [discountBasis]="tokenFactory.livePrices[0].discount"></ultra-game-discount>
            }
            @if (
              !isTokenFactoryAlreadyInLibrary() && (tokenFactory.freeToPlay || tokenFactory.livePrices?.[0]?.amount)
            ) {
              <button
                [disabled]="buyingOptionRestricted()"
                class="btn preview-price preview-price--{{ priceTheme() }} preview-price--{{
                  btnSize()
                }} rounded f-size-12 d-flex d-flex-center"
                [class.preview-price__currency]="
                  tokenFactory.livePrices && !tokenFactory.freeToPlay && priceTheme() === 'purple'
                "
                [class.preview-price__free]="tokenFactory.freeToPlay"
                (click)="priceButtonClicked.emit($event)">
                @if (tokenFactory.freeToPlay) {
                  <span i18n ultraAutotestAttribute="preview-token-preview-price-not-purchased-free">Free</span>
                } @else {
                  @if (tokenFactory.livePrices[0]?.discount > 0) {
                    <ultra-game-price-discount [price]="tokenFactory.livePrices[0]" />
                  } @else {
                    <span ultraAutotestAttribute="preview-token-preview-price-not-purchased">
                      {{ getPrice(tokenFactory.livePrices[0]) }}
                    </span>
                  }
                }
              </button>
            } @else {
              <span class="preview-price preview-price--{{ btnSize() }} preview-price--empty rounded d-flex-center"
                >Details
              </span>
            }
          </div>
        }
      </div>
    }
  </ultra-id-card-header>
}
