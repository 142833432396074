import { DrmOperationResult, JobInfo, ProgressUpdate } from '../interfaces';
import { RenewRoutineEventResponse } from '../../../models';

/**
 * Based on a code we have some functions that are better to keep commented out.
 * We may have a fallback or some specific behavior if these functions/objects are not available,
 * which suits more for the Browser environment (see getDeviceID, isUltraOsLayoutActive, storage, authenticator, etc.)
 * If, for some reason, you have to uncomment them
 * - check that related code and flows still work as expected in the Browser environment.
 */
export const ultOSMock = {
  /* eslint-disable */
  // getAppVersion: () => {},
  // getUltraClientVersion: (callback: (version: string) => void) => {},
  // getDeviceID: (callback: (deviceId: string) => void) => {},
  // getDeviceName: (callback: (name: string) => void) => {},
  onCommandLine: {
    addListener: (callback: (result: unknown) => void) => {},
  },
  LeftPannelOrderBottom: () => {}, // todo CHAPTER GP-21278 probably dead code
  displayUltraOsLayout: () => {},
  displayDefaultPage: () => {},
  // isUltraOsLayoutActive: (callback: (status: boolean) => void) => {},
  openNewTab: (newTabArguments: string) => {},
  openTabGroup: (path: string, url: string, showNewTabButton: boolean) => {},
  showBrowserBar: (data: boolean) => {},
  getDimensionsFile: (callback: (jsonstring: string) => void) => {},
  // storage: {
  //   get: (key: string, callback?: (value: unknown) => void) => new Promise(() => {}),
  //   set: (key: string, value: unknown, callback?: () => void) => new Promise(() => {}),
  //   removeKey: (key: string, callback?: () => void) => new Promise(() => {}),
  //   addListener: (key: string, cb: (result: unknown) => void) => {}, // todo CHAPTER GP-21278 probably dead code
  // },
  settings: {
    // todo CHAPTER most probably `settings` - dead code
    get: (key: string, callback?: (value: unknown) => void) => new Promise(() => {}),
    set: (key: string, value: unknown, callback?: () => void) => new Promise(() => {}),
  },
  // toaster: (title: string, message: string, type: unknown) => void; // todo CHAPTER GP-21278 probably dead code
  // sessionStorage: {
  //   get: (key: string, callback?: (value: unknown) => void) => new Promise(() => {}),
  //   set: (key: string, value: unknown, callback?: () => void) => new Promise(() => {}),
  //   remove: (key: string, callback?: () => void) => new Promise(() => {}),
  // },
  setCookie: (
    cookie: {
      url: string;
      value: string;
    },
    callback: () => void
  ) => new Promise(() => {}),
  itemsDrm: {
    getGatewayUrl: (callback: (url: string) => void) => {}, // todo CHAPTER GP-21278 probably dead code
    setGatewayUrl: (url: string) => true, // todo CHAPTER GP-21278 probably dead code
    activate: (tickets: string[], requestId: string, callback: (result: DrmOperationResult) => void) => {},
    deactivate: (tickets: string[], requestId: string, callback: (result: DrmOperationResult) => void) => {},
    checkLicenseActivation: (tickets: string[], callback: (result: DrmOperationResult) => void) => {},
    setupRenewRoutine: (
      tickets: string[],
      requestId: string,
      timeDeltaInHours: number,
      callback: (result: DrmOperationResult) => void
    ) => {},
    subscribeToRenewRoutineEvents: (callback: (response: RenewRoutineEventResponse) => void) => {},
    unsubscribeFromRenewRoutineEvents: () => {}, // todo CHAPTER GP-21278 probably dead code
  },
  launch: (gameId: string, callback: (result: number) => void) => {},
  // authenticator: {
  //   create: (createParameters: { client: string; nodeosApiUrl: string }) => new Promise(() => {}),
  //   addEventListener: (topic: string) => new Promise(() => {}),
  //   signMessage: (...arguments_: []) => new Promise(() => {}),
  //   signTransaction: (...arguments_: []) => new Promise(() => {}),
  //   abortTransaction: (...arguments_: []) => new Promise(() => {}),
  //   clearWallet: (...arguments_: []) => new Promise(() => {}),
  //   createWallet: (...arguments_: []) => new Promise(() => {}),
  //   hasKeys: (...arguments_: []) => new Promise(() => {}),
  //   linkAccount: (...arguments_: []) => new Promise(() => {}),
  //   lock: (...arguments_: []) => new Promise(() => {}),
  //   unlock: (...arguments_: []) => new Promise(() => {}),
  //   getAccounts: (...arguments_: []) => new Promise(() => {}),
  //   hasWallet: (...arguments_: []) => new Promise(() => {}),
  //   importKeyForWallet: (...arguments_: []) => new Promise(() => {}),
  // },
  downloadManager: {
    initQueue: (queueId: string, callback: (jsonstring: string) => void) => {},
    deleteQueue: (queueId: string, callback: (jsonstring: string) => void) => {},
    getQueue: (queueId: string, callback: (jsonstring: string) => void) => {},
    attachToQueue: (queueId: string, callback: (update: ProgressUpdate) => void) => {},
    add: (queueId: string, jobInfo: JobInfo) => {},
    bulk: (queueId: string, jobInfoList: JobInfo[]) => {},
    start: (queueId: string, callback: (update: ProgressUpdate) => void) => {},
  },
  game: {
    attachToDownloadRepositoryProgress: (
      repositoryId: string,
      branch: string,
      parentRepositoryId: string,
      cb: (result: unknown) => void
    ) => {},
    isRepositoryInstalled: (
      repositoryId: string,
      branch: string,
      parentRepositoryId: string,
      callback: (jsonstring: string) => void
    ) => {},
    checkGamesPresenceByPath: (gamesIds: string[], path: string, callback: (jsonstring: string) => void) => {},
    getRepositoryInstalledVersion: (
      repositoryId: string,
      branch: string,
      parentRepositoryId: string,
      callback: (jsonstring: string) => void
    ) => {},
    downloadRepository: (
      repositoryId: string,
      branch: string,
      parentRepositoryId: string,
      action: string,
      accessToken: string,
      callback: (result: unknown) => void
    ) => {},
    getDefaultGamesPath: (callback: (path: string) => void) => {},
    setDefaultGamesPath: (newPath: string, callback: (path: string) => void) => {},
    selectFolder: (callback: (path: string) => void) => {},
    getInstalledGamesList: () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve([]);
        }, 100);
      }),
    updateGameMetadata: (gameMetadata) => {},
  },
  // checkGamesPresenceByPath: (gamesIds: string[], path: string, callback: (jsonstring: string) => void) => {},
  /* eslint-enable */
};
