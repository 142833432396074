import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { AutotestAttributeDirective } from '../../autotests';

import { OrderbyComponent } from './components/orderby/orderby.component';

@NgModule({
  imports: [AutotestAttributeDirective, CommonModule, NgbDropdownModule],
  declarations: [OrderbyComponent],
  exports: [OrderbyComponent],
})
export class OrderbyModule {}
