import { Component, Input } from '@angular/core';

@Component({
  selector: 'ultra-navbar-search-link',
  templateUrl: './navbar-search-link.component.html',
  styleUrls: ['./navbar-search-link.component.scss'],
})
export class NavbarSearchLinkComponent {
  @Input() link = '/';

  @Input() linkParams = {};

  @Input() label = 'Search...';
}
