import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CurrencyType } from '@ultra/core/models';

import { ExchangeGqlService } from './exchange-gql.service';
import { ExchangeRate, ExchangeRateParams } from './interfaces/exchange-rate.interface';

@Injectable({
  providedIn: 'root',
})
export class ExchangeFacadeService {
  constructor(private exchangeGqlService: ExchangeGqlService) {}

  public getFromFiatToUosExchangeRate(currency: CurrencyType): Observable<ExchangeRate> {
    const exchangeRateParams: ExchangeRateParams = {
      from: currency,
      to: CurrencyType.UOS,
    };
    return this.exchangeGqlService.getExchangeRate(exchangeRateParams);
  }

  public getFromUosToFiatExchangeRate(currency: CurrencyType): Observable<ExchangeRate> {
    const exchangeRateParams: ExchangeRateParams = {
      from: CurrencyType.UOS,
      to: currency,
    };
    return this.exchangeGqlService.getExchangeRate(exchangeRateParams);
  }
}
