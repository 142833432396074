import { IOrderByFilter } from '../../interfaces/order-by-filter.interface';

export const orderByValues: IOrderByFilter[] = [
  {
    label: 'Relevance',
    value: 'RELEVANCE',
  },
  {
    label: 'Release date',
    value: 'RELEASE_DATE',
  },
  {
    label: 'Name',
    value: 'NAME',
  },
];

export const orderLibraryByValue: IOrderByFilter[] = [
  {
    label: 'A-Z',
    value: 'ALPHABETICALLY',
  },
  {
    label: 'Z-A',
    value: 'ALPHABETICALLY_REVERSE',
  },
];
