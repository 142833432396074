import { isPlatformBrowser } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { ApolloLink, InMemoryCache, split } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { Kind, OperationTypeNode } from 'graphql';
import { createClient, MessageType, stringifyMessage, WebSocket } from 'graphql-ws';

import { EnvironmentConfig } from '@ultra/environment';

import { AuthService } from '../services/auth/auth.service';

import { ApolloClientName } from './enums';
import { ApiGraphQLService } from './services';

@NgModule({
  imports: [ApolloModule],
  providers: [ApiGraphQLService, provideHttpClient()],
})
export class GraphQLClientModule {
  isPlatformBrowser = isPlatformBrowser(this.platformID);

  constructor(
    private apollo: Apollo,
    private httpLink: HttpLink,
    private envConfig: EnvironmentConfig,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformID: any,
  ) {
    const gameGraphQL = `${envConfig.BASE_PATH_GRAPH}/games/v1/graphql`;
    const tokenFactoryGraphQL = `${envConfig.BASE_PATH_GRAPH}/tokenfactories/v1/graphql`;
    const companyGraphQL = `${envConfig.BASE_PATH_GRAPH}/companies/v1/graphql`;
    const usersGraphQL = `${envConfig.BASE_PATH_GRAPH}/users/v1/graphql`;
    const referentialGraphQL = `${envConfig.BASE_PATH_GRAPH}/referential/v1/graphql`;
    const ultraCloudGraphQL = `${envConfig.BASE_PATH_GRAPH}/ultracloud/v1/graphql`;
    const permissionGraphQL = `${envConfig.BASE_PATH_GRAPH}/permission/v1/graphql`;
    const orderGraphQL = `${envConfig.BASE_PATH_GRAPH}/order/v1/graphql`;
    const exchangeGraphQL = `${envConfig.BASE_PATH_GRAPH}/exchange/v1/graphql`;
    const paymentGraphQL = `${envConfig.BASE_PATH_GRAPH}/payment/v1/graphql`;

    // WebSocket connections
    const exchangeGraphQLWS = `${envConfig.BASE_PATH_GRAPH_SOCKET}/exchange/v1/graphql`;
    const orderGraphQLWS = `${envConfig.BASE_PATH_GRAPH_SOCKET}/order/v1/graphql`;
    const paymentGraphQLWS = `${envConfig.BASE_PATH_GRAPH_SOCKET}/payment/v1/graphql`;
    const userGraphQLWS = `${envConfig.BASE_PATH_GRAPH_SOCKET}/users/v1/graphql`;

    // Create GraphQL clients
    apollo.createDefault({
      link: ApolloLink.from([this.getResponseHeaders(), httpLink.create({ uri: gameGraphQL })]),
      cache: new InMemoryCache({ addTypename: false }),
    });

    apollo.createNamed(ApolloClientName.TOKEN_FACTORIES, {
      link: httpLink.create({ uri: tokenFactoryGraphQL }),
      cache: new InMemoryCache({ addTypename: false }),
    });

    apollo.createNamed(ApolloClientName.COMPANIES, {
      link: httpLink.create({ uri: companyGraphQL }),
      cache: new InMemoryCache({ addTypename: false }),
    });

    apollo.createNamed(ApolloClientName.USERS, {
      link: ApolloLink.from([this.getErrorLink(), this.getSplitLink(usersGraphQL, userGraphQLWS)]),
      cache: new InMemoryCache({ addTypename: false }),
    });

    apollo.createNamed(ApolloClientName.REFERENTIAL, {
      link: httpLink.create({ uri: referentialGraphQL }),
      cache: new InMemoryCache({ addTypename: false }),
    });

    apollo.createNamed(ApolloClientName.ULTRA_CLOUD, {
      link: httpLink.create({ uri: ultraCloudGraphQL }),
      cache: new InMemoryCache({ addTypename: false }),
    });

    apollo.createNamed(ApolloClientName.PERMISSION, {
      link: httpLink.create({ uri: permissionGraphQL }),
      cache: new InMemoryCache({ addTypename: false }),
    });

    apollo.createNamed(ApolloClientName.ORDER, {
      link: ApolloLink.from([this.getErrorLink(), this.getSplitLink(orderGraphQL, orderGraphQLWS)]),
      cache: new InMemoryCache({ addTypename: false }),
    });

    apollo.createNamed(ApolloClientName.EXCHANGE, {
      link: ApolloLink.from([this.getErrorLink(), this.getSplitLink(exchangeGraphQL, exchangeGraphQLWS)]),
      cache: new InMemoryCache({ addTypename: false }),
    });

    apollo.createNamed(ApolloClientName.PAYMENT, {
      link: ApolloLink.from([this.getErrorLink(), this.getSplitLink(paymentGraphQL, paymentGraphQLWS)]),
      cache: new InMemoryCache({ addTypename: false }),
    });
  }

  /**
   * Use Apollo `split` to provide a different link depending on the operation type
   *
   * @private
   * @param {string} httpEndpoint
   * @param {string} webSocketEndpoint
   * @returns ApolloLink
   * @memberof GraphQLClientModule
   */
  private getSplitLink(httpEndpoint: string, webSocketEndpoint: string) {
    const httpLink = this.httpLink.create({ uri: httpEndpoint });

    if (this.isPlatformBrowser) {
      const wsLink = this.buildWebSocketLink(webSocketEndpoint);

      return split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === Kind.OPERATION_DEFINITION && definition.operation === OperationTypeNode.SUBSCRIPTION
          );
        },
        wsLink,
        httpLink,
      );
    } else {
      return ApolloLink.from([httpLink]);
    }
  }

  private getErrorLink() {
    return onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, path }: any) =>
          console.error(`[GraphQL error] Message: ${message.message}, Path: ${path}`),
        );
      }

      if (networkError) {
        console.error(`[Network error] ${networkError.message}`);
      }
    });
  }

  private getResponseHeaders(): ApolloLink {
    return new ApolloLink((operation, forward) => {
      return forward(operation).map((response) => {
        const context = operation.getContext();
        const { headers } = context.response;
        const meta = { date: headers.get('date') };
        if (response.data) {
          response.data.meta = meta;
        } else {
          response.data = { meta };
        }
        return response;
      });
    });
  }

  /**
   * Build WebSocket client link
   * @param {string} url
   * @returns {GraphQLWsLink}
   */
  private buildWebSocketLink(url: string): GraphQLWsLink {
    let activeSocket: WebSocket;
    const connectionParams = (): Record<string, unknown> => ({
      headers: {
        authorization: this.authService.getAuthorizationHeader(),
      },
    });
    const sendCredentials = () =>
      activeSocket.send(
        stringifyMessage({
          type: MessageType.Ping,
          payload: connectionParams(),
        }),
      );

    return new GraphQLWsLink(
      createClient({
        url,
        lazy: true, // connects only when first subscription created, and delay the socket initialization
        retryAttempts: 3, // reconnect attempts
        keepAlive: 30_000, // ping server every 30 seconds
        shouldRetry: () => true,
        connectionParams,
        on: {
          opened: (socket: WebSocket) => {
            activeSocket = socket; // save current socket instance
          },
          ping: () => sendCredentials(), // send credentials every ping event
        },
      }),
    );
  }
}
