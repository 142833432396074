import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { UserCurrency } from '@ultra/core/models';
import { UserFacadeService } from '@ultra/core/stores';

import { ExchangeFacadeService } from './exchange-facade.service';
import { CurrencyHelper } from './helpers/currency.helper';
import { ExchangeRate } from './interfaces/exchange-rate.interface';

@Injectable({
  providedIn: 'root',
})
export class UserCurrencyConversionService {
  private uosToFiatExchangeSubject = new ReplaySubject<ExchangeRate>(1);
  private fiatToUosExchangeSubject = new ReplaySubject<ExchangeRate>(1);

  public userCurrency$: Observable<UserCurrency>;
  public uosToFiatExchange$: Observable<ExchangeRate>;
  public fiatToUosExchange$: Observable<ExchangeRate>;

  constructor(private userFacadeService: UserFacadeService, private exchangeFacadeService: ExchangeFacadeService) {
    this.userCurrency$ = this.userCurrency();
    this.uosToFiatExchange$ = this.uosToFiatExchangeSubject.asObservable();
    this.fiatToUosExchange$ = this.fiatToUosExchangeSubject.asObservable();
    this.initializeExchangeRates();
  }

  private initializeExchangeRates() {
    this.userCurrency$
      .pipe(
        switchMap(({ countryCurrency }) =>
          combineLatest([
            this.exchangeFacadeService.getFromUosToFiatExchangeRate(countryCurrency).pipe(catchError(() => of(null))),
            this.exchangeFacadeService.getFromFiatToUosExchangeRate(countryCurrency).pipe(catchError(() => of(null))),
          ])
        ),
        tap(([uosToFiatExchange, fiatToUosExchange]) => {
          this.uosToFiatExchangeSubject.next(uosToFiatExchange);
          this.fiatToUosExchangeSubject.next(fiatToUosExchange);
        })
      )
      .subscribe();
  }

  private userCurrency(): Observable<UserCurrency> {
    return this.userFacadeService.user$.pipe(
      map((user) => user?.personalData),
      filter((personalData) => !!personalData),
      map(({ countryCurrency, currencySymbol }): UserCurrency => ({ countryCurrency, currencySymbol })),
      map(CurrencyHelper.getUserCurrency),
      distinctUntilChanged((prev, curr) => prev.countryCurrency === curr.countryCurrency)
    );
  }
}
