import { DecimalPipe } from '@angular/common';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { ITokenPrice } from '../../../../models/token-factory/interfaces/token-price.interface';
import { TransactionAppService } from '../../../../services/transaction/transaction-app.service';
import { DisplayPrices } from '../../../../utils/';
import { TokenFactoryBasketService } from '../../../basket/services/token-factory-basket.service';

@Component({
  selector: 'ultra-navbar-cart',
  templateUrl: './navbar-cart.component.html',
  styleUrls: ['./navbar-cart.component.scss'],
  providers: [DecimalPipe],
})
export class NavbarCartComponent extends DisplayPrices {
  itemsCount$: Observable<number>;
  totalFiat$: Observable<ITokenPrice>;

  constructor(
    private basketService: TokenFactoryBasketService,
    protected decimalPipe: DecimalPipe,
    public transactionService: TransactionAppService
  ) {
    super(decimalPipe);
  }

  /**
   * have to clear games because ShoppingCartService not destroyed after logout and contains all games, even if LS was reset
   */
  resetCart(): void {
    this.basketService.clearItems().subscribe();
  }
}
