import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import { map, Observable, switchMap } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

import { LinkHelper } from '../../../../services/helpers/link.helper';

@Component({
  selector: 'ultra-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  legalTermsLink: string = inject(LinkHelper).legalTermsLink();
  year: number = new Date().getFullYear();
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private router: Router = inject(Router);
  visibility$: Observable<{
    footer: boolean;
    legalButton: boolean;
  }> = this.router.events.pipe(
    filter((event: Scroll) => event.routerEvent instanceof NavigationEnd),
    startWith(true),
    switchMap(() => this.activatedRoute.queryParams),
    map(({ navigation }) => ({
      footer: navigation !== 'hidden',
      legalButton: !this.router.url.includes('/auth/'),
    }))
  );
}
