import { Game } from '../../../models/game/models/game.model';
import { ITokenFactory } from '../../../models/token-factory/interfaces/token-factory.interface';
import { ITokenPrice } from '../../../models/token-factory/interfaces/token-price.interface';
import { BasketItemType, IBasketItem, IStorageBasketItem } from '../interfaces/basket-item.interface';

export class TokenFactoryItem implements IBasketItem {
  type = BasketItemType.TOKEN_FACTORY;
  tokenType: string;
  id: string;
  name: string;
  subName: string;
  creatorName: string;
  creatorVerified: boolean;
  imageUrl: string;
  price: ITokenPrice;

  gameId: string;
  tokenFactory: ITokenFactory;

  constructor(game: Game, tokenFactory: ITokenFactory) {
    this.tokenType = tokenFactory.contentType.code;
    this.id = tokenFactory.id;
    this.name = tokenFactory.name;
    this.subName = game.title;
    this.creatorName = game.publisher.name;
    this.imageUrl = tokenFactory.image && tokenFactory.image.images[0]?.url;
    this.price = tokenFactory.livePrices?.[0];
    this.gameId = game.id;
    this.tokenFactory = tokenFactory;
  }

  toStorage(): IStorageBasketItem {
    return {
      tokenId: this.id,
      gameId: this.gameId,
    };
  }
}
