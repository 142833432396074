import { DecimalPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, distinctUntilChanged, map, of, ReplaySubject, Subject, switchMap } from 'rxjs';

import { AuthService } from '@ultra/core/auth';

import { IStoreTokenFactory } from '../../../models/token-factory/interfaces/token-factory.interface';
import { GeofencingService } from '../../../stores/geofencing/providers/geofencing.service';
import { DisplayPrices } from '../../../utils/display-prices';
import {
  IdCardContentHeaderConfig,
  IdCardEntity,
  IdCardPanelTransformerHelper,
  IdCardPropertyTabs,
} from '../../id-card-content';
import { ISelectedTab } from '../../tabs/interfaces/selected-tab.interface';
import { TabGroupComponent } from '../../tabs/tab-group/tab-group.component';
import { getBackgroundImage } from '../token-factory-preview.helper';
import { TokenFactoryPreviewFacadeService } from '../token-factory-preview-facade.service';
/**
 * Preview of the Game Factory card opened in a dialog.
 *
 * Since this component does not get the tabGroup On Init, we should wait until
 * is available.
 */
@UntilDestroy()
@Component({
  selector: 'ultra-token-preview-body',
  templateUrl: './token-preview-body.component.html',
  styleUrls: ['./token-preview-body.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DecimalPipe],
})
export class TokenPreviewBodyComponent extends DisplayPrices implements AfterViewInit, OnInit {
  @Input() tokenFactory: IStoreTokenFactory;
  @Input() companyName: string;
  @Input() isTokenFactoryAlreadyInLibrary = false;
  /**
   * True when the token factory is not available for purchase
   *
   * Used for Exclusive Access.
   */
  @Input() buyingOptionRestricted = false;

  @Input() selectedTab: IdCardPropertyTabs = IdCardPropertyTabs.OVERVIEW;
  /**
   * Emits when the close button is clicked.
   */
  @Output() closed: EventEmitter<void> = new EventEmitter();
  @Output() priceButtonClicked: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(TabGroupComponent)
  tabGroup: TabGroupComponent;
  isAuthenticated = inject(AuthService).isAuthenticated();

  tokenFactoryIdCardEntity: IdCardEntity;

  backgroundImage = '';
  showBackground = true;
  properties = IdCardPropertyTabs;
  showTokenFactoryProperties = false;
  idCardContentHeaderConfig: Partial<IdCardContentHeaderConfig> = {
    hideCopyToClipboard: true,
  };

  uniqFactoryIdEntityCard: IdCardEntity;
  private tokenFactorySubject: Subject<IStoreTokenFactory> = new ReplaySubject<IStoreTokenFactory>(1);
  private tokenFactorySubject$ = this.tokenFactorySubject.asObservable();

  private tabSubject: Subject<number> = new ReplaySubject<number>(1);
  private tabSubject$ = this.tabSubject.asObservable();

  private tokenFactoryPreviewFacadeService = inject(TokenFactoryPreviewFacadeService);
  geofencingService = inject(GeofencingService);

  constructor(
    private cd: ChangeDetectorRef,
    protected decimalPipe: DecimalPipe,
  ) {
    super(decimalPipe);
  }

  ngOnInit(): void {
    this.showTokenFactoryProperties = !this.tokenFactory.onChainId;
    this.tokenFactoryIdCardEntity = this.tokenFactoryPreviewFacadeService.getTokenFactoryEntity(this.tokenFactory);
    this.tokenFactorySubject.next(this.tokenFactory);
    const tabNumber = IdCardPanelTransformerHelper.transform(this.selectedTab).tabNumber;
    this.tabSubject.next(tabNumber);
  }
  ngAfterViewInit() {
    combineLatest([this.tabSubject$, this.tokenFactorySubject$])
      .pipe(
        distinctUntilChanged(),
        switchMap(([tabNumber, tokenFactory]) => {
          if (
            this.isAuthenticated &&
            tokenFactory.onChainId &&
            this.uniqFactoryIdEntityCard?.onChainId !== tokenFactory.onChainId.toString()
          ) {
            return this.tokenFactoryPreviewFacadeService
              .getUniqFactoryEntity(tokenFactory)
              .pipe(map((uniqFactoryIdEntityCard) => [tabNumber, uniqFactoryIdEntityCard]));
          }
          return of([tabNumber]);
        }),
        untilDestroyed(this),
      )
      .subscribe(([tabNumber, uniqFactoryIdEntityCard]: [tabNumber: number, uniqFactoryIdEntityCard: IdCardEntity]) => {
        this.backgroundImage = tabNumber === 0 ? getBackgroundImage(this.tokenFactoryIdCardEntity) : '';
        this.tabGroup.select(tabNumber);
        if (uniqFactoryIdEntityCard) {
          this.uniqFactoryIdEntityCard = uniqFactoryIdEntityCard;
        }
        this.cd.detectChanges();
      });
  }

  onTabChange({ index }: Partial<ISelectedTab>) {
    this.toggleTabBackground(index);
    this.tabSubject.next(index);
  }

  getUniqActivePropertyTab(): IdCardPropertyTabs {
    if (this.selectedTab === IdCardPropertyTabs.OVERVIEW) {
      return IdCardPropertyTabs.QUANTITY;
    } else {
      return this.selectedTab;
    }
  }

  emitPriceButtonClicked(event: any) {
    this.priceButtonClicked.emit(event);
  }

  toggleTabBackground(tabIndex: number): void {
    this.showBackground = tabIndex === 0;
  }

  handlePropertyQuickAccess(property: IdCardPropertyTabs) {
    this.selectedTab = property;
    this.onTabChange({ index: IdCardPanelTransformerHelper.transform(property).tabNumber });
  }
}
